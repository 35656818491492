import { createStore, combineReducers, applyMiddleware , compose } from 'redux';
import thunk from "redux-thunk";
import logger from 'redux-logger'
import {Auth} from "./Auth";
import {reduxFirestore , getFirestore , firestoreReducer} from "redux-firestore";
import {reactReduxFirebase , getFirebase} from 'react-redux-firebase'
import {sendMessageStatus} from "./sendMessageStatus";
import {config} from "../firebase/config";
import firebase from "firebase";

const myFirebase = firebase.initializeApp(config);

export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            auth: Auth,
            firestore : firestoreReducer,
            sendMessageStatus : sendMessageStatus
        }),
        compose(
            applyMiddleware(thunk.withExtraArgument({getFirestore , getFirebase}), logger),
            reduxFirestore(myFirebase),
            reactReduxFirebase(myFirebase)
        )

    );
    return store;
};