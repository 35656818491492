import avatar from "../assets/img/avatar_1.png";
import * as ACTIONTYPES from './ActionTypes'
import {TRIED_CHOICE} from "./ActionTypes";

export const Auth = (state={isLoading: true , errMess : null , data : null}
    ,action)=>{
        switch (action.type) {
            case ACTIONTYPES.LOADING_USER :
                return {...state , isLoading: true , data: null , errMess: null};
            case ACTIONTYPES.GET_USER:
                return {...state , isLoading: false , errMess: null , data: action.payload};
                break;
            case ACTIONTYPES.USER_FAILED:
                return {...state , isLoading: false , errMess: action.payload };
            case ACTIONTYPES.INC_HELP:
                let data = state.data;
                data[action.payload.categoryName].help = Math.min(10  , data[action.payload.categoryName].help+1);
                data[action.payload.categoryName].solved.push(action.payload.numQuiz);
                data[action.payload.categoryName].solved = [...new Set(data[action.payload.categoryName].solved)];
                console.log('SOLVED NOW IS : ', data[action.payload.categoryName].solved);
                data[action.payload.categoryName].quizdata['q' + action.payload.numQuiz].strictLettersLessOpacity = action.payload.answer.split('');
                data.totalScore++;
                data[action.payload.categoryName].score++;
                sessionStorage.setItem('Auth', JSON.stringify(data));
                return {...state , isLoading: false , data: data};
            case ACTIONTYPES.DEC_HELP:
                console.log('DEC HELP IN AUTH.JS');
                let data2 = state.data;
                data2[action.payload.categoryName].help--;
                console.log('INDICES IN DEC HELP :', action.payload.indices);
                action.payload.indices.forEach((indice,index)=>{
                    data2[action.payload.categoryName].quizdata['q'+action.payload.numQuiz].strictCorrect.push(indice);
                    data2[action.payload.categoryName].quizdata['q'+action.payload.numQuiz].strictLettersLessOpacity.push(action.payload.letters[index]);
                });
                data2[action.payload.categoryName].quizdata['q'+action.payload.numQuiz].strictCorrect=[...new Set(data2[action.payload.categoryName].quizdata['q'+action.payload.numQuiz].strictCorrect)];
                sessionStorage.setItem('Auth', JSON.stringify(data2));
                return {...state , isLoading: false , data: data2};
            case ACTIONTYPES.DEC_HELP2:
                let data3 = state.data;
                data3[action.payload.categoryName].help--;
                data3[action.payload.categoryName].quizdata['q' + action.payload.numQuiz].strictWrong.push(action.payload.strictWrong);
                data3[action.payload.categoryName].quizdata['q' + action.payload.numQuiz].strictWrong = data3[action.payload.categoryName].quizdata['q' + action.payload.numQuiz].strictWrong.filter(Wrong => Wrong != null);
                data3[action.payload.categoryName].quizdata['q' + action.payload.numQuiz].order = action.payload.order;
                sessionStorage.setItem('Auth', JSON.stringify(data3));
                return {...state , isLoading: false , data: data3};
            case ACTIONTYPES.SET_ORDER_CHOICES:
                let data5=state.data;
                data5[action.payload.categoryName].quizdata['q' + action.payload.numQuiz].order = action.payload.order;
                sessionStorage.setItem('Auth', JSON.stringify(data5));
                return {...state ,  data: data5};
            case TRIED_CHOICE:
                let data4 = state.data;
                if(action.payload.correct){
                    data4[action.payload.categoryName].help = Math.min(10  , data4[action.payload.categoryName].help+1);
                    data4[action.payload.categoryName].solved.push(action.payload.numQuiz);
                    data4[action.payload.categoryName].solved = [...new Set(data4[action.payload.categoryName].solved)];
                    data4.totalScore++;
                    data4[action.payload.categoryName].score++;
                }
                data4[action.payload.categoryName].quizdata['q'+action.payload.numQuiz].tried = true;
                sessionStorage.setItem('Auth', JSON.stringify(data4));
                return {...state , isLoading: false , data: data4};
            default:
                return state
        }
};