
import './styles.css'
import React , {Component} from 'react'
import {lock} from '../../assets/img/lock.svg'

class Stage  extends  Component{
    render(){
        return(
            <React.Fragment>
                <nav className="navbar  fixed-top">
                    <div className="navbar-brand ml-2"><a href="https://google.com"  style={{color : '#eee'}}><i className="fas fa-arrow-left fa-2x mr-2"/></a> <svg  id="slashNavBar" style={{position: 'absolute' , top: '15px'}}  height="60" width="40"><line x1="0" y1="0" x2="70" y2="200" style={{stroke:'white' , strokeWidth:2}} /></svg> </div>
                    <div className="navbar-nav" id="QuizLevel"><div>Our Quizzes</div></div>
                    <ul className="navbar-nav  row  align-items-center">
                        <li className="nav-item m-2 mr-lg-4 ml-lg-4 mr-4"><i className="fas fa-coins" style={{height:"20px" , color : "darkorange"}}/> <p style={{textAlign: 'center',  margin:'0'}}>100</p></li>
                    </ul>
                </nav>
                <div className="container mt-3">
                    <div className="row justify-content-center">
                        <p id="categoryName" className="categoryName" style={{height:"20px"}}>Countries Flags</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="stage col-10 mb-2">
                            <div className="stageNumber row justify-content-between"><p>Stage1</p></div>
                            <progress className="mt-1 mb-1 col-10 progress-bar progress-bar-animated progressStages" role="progressbar"  max="100" value="40"></progress>
                            <div className="stageState row">
                                <p>13/30</p> <p className="mb-1 mr-2">40%</p>
                            </div>
                        </div>
                        <div className="stage col-10 mb-2">
                            <div className="stageNumber row justify-content-between"><p>Stage1</p> <img style={{height:"20px"}} className="mb-1 mr-2 mt-1" src={lock} alt=""/></div>
                            <progress className="mt-1 mb-1 col-10 progress-bar progress-bar-animated progressStages" role="progressbar"  max="100" value="0"></progress>
                            <div className="stageState row">
                                <p>0/30</p> <p className="mb-1 mr-2">0%</p>
                            </div>
                        </div>
                        <div className="stage col-10 mb-2">
                            <div className="stageNumber row justify-content-between"><p>Stage1</p><img style={{height:"20px"}} className="mb-1 mr-2 mt-1" src={lock} alt=""/></div>
                            <progress className="mt-1 mb-1 col-10 progress-bar progress-bar-animated progressStages" role="progressbar"  max="100" value="0"></progress>
                            <div className="stageState row">
                                <p>0/30</p> <p className="mb-1 mr-2">0%</p>
                            </div>
                        </div>
                        <div className="stage col-10 mb-2">
                            <div className="stageNumber row justify-content-between"><p>Stage1</p><img style={{height:"20px"}} className="mb-1 mr-2 mt-1" src={lock} alt=""/></div>
                            <progress className="mt-1 mb-1 col-10 progress-bar progress-bar-animated progressStages" role="progressbar"  max="100" value="0"></progress>
                            <div className="stageState row">
                                <p>0/30</p> <p className="mb-1 mr-2">0%</p>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Stage;