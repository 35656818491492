import React, { Component } from 'react';
import logo from './logo.svg';
import {HashRouter} from "react-router-dom";
import './fontawesome/css/all.min.css'
import '../node_modules/animate.css/animate.min.css'
import './App.css';
import Categories from './Components/categoriesComponent/categoriesComponent'
import Stage from './Components/stageComponent/stageComponent';
import {QuizLetters} from './Components/quizLettersComponent/quizLettersComponent'
import QuizChoices from "./Components/quizChoicesComponent/quizChoicesComponent";
import Main from './Components/mainComponent'
// import '../node_modules/j'
import {Provider} from 'react-redux'
import {ConfigureStore} from "./redux/configureStore";
import { loadReCaptcha } from 'react-recaptcha-v3'

const store = ConfigureStore();
class App extends Component {
    componentDidMount() {
        loadReCaptcha('6LcKwMcZAAAAANfS-XXfr5QWaQrmPEZhmrhijOZt', ()=>{
            console.log('recacptcha is loaded now');
        });
      }
    render(){
        return (

            <Provider store={store}>
                <HashRouter>
                    <div className="App">
                        <Main/>
                    </div>
                </HashRouter>
            </Provider>
         
           );
    }
 
}

export default App;
