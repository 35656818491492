import React from 'react';
import loadingIcon from "../../json/Loading.json";

export const LoadingStatus = () => {
    return(
        <React.Fragment>
            <h3 style={{color :'#1ab394'}}>Loading ...</h3>
            <lottie-player src={JSON.stringify(loadingIcon)} background="transparent"  speed="1"  style={{width: '300px', height: '300px' , justifyContent : 'center' , display : 'contents' }}  autoplay loop/>
        </React.Fragment>
    );
};
