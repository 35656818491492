import React , {Component} from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import QuizChoices from './quizChoicesComponent/quizChoicesComponent';
import QuizLetters from './quizLettersComponent/quizLettersComponent'
import Categories from "./categoriesComponent/categoriesComponent";
import Ads from "./PoliciesComponent/adspolicy";
import Privacy from './PoliciesComponent/privacyPolicy';
import {connect} from "react-redux";
import  Header from './headerComponent/headerComponent';
import Footer from './footerComponent';
import {Modal , ModalHeader , ModalBody , Form , FormGroup , Input , Label } from 'reactstrap'
import {loadUser , callIncHelp , callDecHelp , decHelp2 , triedChoice , setOrderChoices , sendMessage , closeModalSendMessage} from "../redux/ActionCreators";
import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {Loading} from "./loadingComponent";
import {Helmet} from "react-helmet";
import Contact from "./contactUsComponent/contactUsComponent";

const mapStateToProps = state => {
    
    return {
        auth: state.auth,
        Categories : sessionStorage.getItem('Categories')? JSON.parse(sessionStorage.getItem('Categories')) :  state.firestore.ordered.Categories,
        Esthetics : state.firestore.ordered.Esthetics,
        sendMessageStatus : state.sendMessageStatus
    }
};
const mapDispatchToProps = (dispatch) => ({
    loadUser: () => dispatch(loadUser()),
    callIncHelp: (categoryName , numQuiz)=>dispatch(callIncHelp(categoryName , numQuiz)),
    callDecHelp: (categoryName , numQuiz , ...indices) => dispatch(callDecHelp(categoryName , numQuiz , ...indices)),
    decHelp2 : (categoryName , numQuiz , wrong , order)=>{dispatch(decHelp2(categoryName , numQuiz , wrong , order))},
    triedChoice : (categoryName , numQuiz , correct)=>{dispatch(triedChoice(categoryName , numQuiz , correct))},
    setOrderChoices: (categoryName , numQuiz , order)=>{dispatch(setOrderChoices(categoryName,numQuiz,order))},
    sendMessage : (name ,email , message)=>{dispatch(sendMessage(name , email , message))},
    closeModalSendMessage : ()=>{dispatch(closeModalSendMessage())}
});


class Main extends Component {
    constructor(props) {
        super(props);
    };
    componentDidMount() {
        console.log('REFERRER =', window.history);
        console.log('AUTH = ', this.props.auth);
        console.log('CATEGORIES FROM ACTION CREATORS :');
        let interv = setInterval(()=>{
            this.props.loadUser();
            console.log(this.props.auth);
            if(this.props.Categories){
                clearInterval(interv);
            }
        },500);

    };
    render() {
        if(!this.props.Esthetics){
            return <Loading/>
        }else{
            const Quiz = ({match})=>{
                if(!this.props.Categories){
                    return <Loading/>
                }
                else if(match.params.type==='1'){
                    if(this.props.auth.data){
                        const category = this.props.Categories.filter(category => category.categoryName === match.params.categoryName)[0];
                        const quiz = category.quizzes[match.params.quizIndice];
                        let lastQuiz = false;
                        if(category.quizzes.length === Number(match.params.quizIndice) +1){
                            lastQuiz=true
                        }
                        let solved = false;
                        if(this.props.auth && this.props.auth.data[match.params.categoryName].solved.indexOf(Number(match.params.quizIndice)) !==-1){
                            solved=true
                        }
                        let lettersLower = [...this.props.auth.data[match.params.categoryName].quizdata['q' + Number(match.params.quizIndice)].letters];
                        lettersLower.forEach((letterUpper,index)=>{
                            lettersLower[index] = letterUpper.toLowerCase();
                        });
                        return (
                            <QuizLetters casesBorder={this.props.Esthetics ? this.props.Esthetics.filter(esthetic=>esthetic.id==='Borders')[0].CasesQuizType1 : null} lettersBorder={this.props.Esthetics ? this.props.Esthetics.filter(esthetic=>esthetic.id==='Borders')[0].LettersQuizType1 : null} imageBorder={this.props.Esthetics ? this.props.Esthetics.filter(esthetic=>esthetic.id==='Borders')[0].ImageQuiz : null} strictLetterslessOpacity={this.props.auth.data[match.params.categoryName].quizdata['q'+Number(match.params.quizIndice)].strictLettersLessOpacity} solved={solved} strictCorrect={this.props.auth.data[match.params.categoryName].quizdata['q'+Number(match.params.quizIndice)].strictCorrect} letters={ this.props.auth.data[match.params.categoryName].quizdata['q'+Number(match.params.quizIndice)].letters } lettersLower={lettersLower} callDecHelp={this.props.callDecHelp} callIncHelp={this.props.callIncHelp} help={this.props.auth.data[match.params.categoryName].help }  quiz={quiz} last={lastQuiz}  prevType={Number(match.params.quizIndice)===0 ? null : category.quizzes[Number(match.params.quizIndice)-1].type } nextType={lastQuiz? null : category.quizzes[Number(match.params.quizIndice)+1].type}  indice={Number(match.params.quizIndice)} category={match.params.categoryName}/>
                        )
                    } else{
                        return(<Loading/>)
                    }

                }else if(match.params.type==='2'){
                    if(this.props.auth.data) {
                        const category = this.props.Categories.filter(category => category.categoryName === match.params.categoryName)[0];
                        const quiz = category.quizzes[match.params.quizIndice];
                        let lastQuiz = false;
                        if (category.quizzes.length === Number(match.params.quizIndice) + 1) {
                            lastQuiz = true
                        }
                        return (
                            <QuizChoices imageBorder={this.props.Esthetics ? this.props.Esthetics.filter(esthetic=>esthetic.id==='Borders')[0].ImageQuiz : null}
                                         tried={this.props.auth.data[match.params.categoryName].quizdata['q'+Number(match.params.quizIndice)].tried}
                                         triedChoice={this.props.triedChoice}
                                         strictWrong={this.props.auth.data[match.params.categoryName].quizdata['q'+Number(match.params.quizIndice)].strictWrong} decHelp2={this.props.decHelp2}
                                         order={this.props.auth.data[match.params.categoryName].quizdata['q'+Number(match.params.quizIndice)].order}
                                         help={this.props.auth.data[match.params.categoryName].help }
                                         quiz={quiz} last={lastQuiz}
                                         prevType={Number(match.params.quizIndice) === 0 ? null : category.quizzes[Number(match.params.quizIndice) - 1].type}
                                         nextType={lastQuiz ? null : category.quizzes[Number(match.params.quizIndice) + 1].type}
                                         indice={Number(match.params.quizIndice)} category={match.params.categoryName}
                                         setOrderChoices={this.props.setOrderChoices}
                            />
                        )
                    }else{

                        return <Loading/>
                    }
                }
            };
            console.log('CATEGORIES FROM MAIN ' , this.props.Categories);
            let googleFonts = null;
            let colors = null;
            let casesBorder =null;
            let imageBorder = null;
            let categoriesBorders =null;
            let choicesBorder= null;
            if(this.props.Esthetics){
                googleFonts = this.props.Esthetics.filter(esthetic => esthetic.id === 'Fonts')[0];
                colors = this.props.Esthetics.filter(esthetic => esthetic.id === 'Colors')[0];
                casesBorder =this.props.Esthetics.filter(esthetic=>esthetic.id==='Borders')[0].CasesQuizType1;
                imageBorder = this.props.Esthetics.filter(esthetic => esthetic.id === 'Borders')[0].ImageQuiz;
                categoriesBorders =this.props.Esthetics.filter(esthetic=>esthetic.id==='Borders')[0].CategoriesBorders;
                choicesBorder= this.props.Esthetics.filter(esthetic=>esthetic.id==='Borders')[0].ChoicesQuizType2;
            }
            console.log('GOOGLE FONTS =', googleFonts);

            return(
                <React.Fragment>
                    {this.props.Esthetics ?
                        <Helmet style={[{
                            "cssText": `
                            :root {
                                --CategoriesBorders : solid ${categoriesBorders.borderColor} ${categoriesBorders.borderWidth};
                                --CategoriesRadiusBorders : ${categoriesBorders.borderRadius};
                                --choicesBorders : solid ${choicesBorder.borderColor} ${choicesBorder.borderWidth};
                                --choicesRadiusBorders : ${choicesBorder.borderRadius};
                                --ImageQuizBorders : solid ${imageBorder.borderColor} ${imageBorder.borderWidth};
                                --ImageQuizRadiusBorders: ${imageBorder.borderRadius};
                                --CasesBorder : solid ${casesBorder.borderColor} ${casesBorder.borderWidth}; 
                                --CasesBorderRadius : ${casesBorder.borderRadius};
                                --defaultFont: ${googleFonts.default.css};
                                --Letters: ${googleFonts.LettersQuizType1.css};
                                --Choices: ${googleFonts.ChoicesQuizType2.css};
                                --CasesColor:${colors.Cases.color};
                                --CasesBackground :${colors.Cases.background};
                                --CasesCorrectsColor : ${colors.CasesCorrects.color};
                                --CasesCorrectsBackground :${colors.CasesCorrects.background};
                                --LettersColor:${colors.Letters.color};
                                --LettersBackground:${colors.Letters.background};
                                --bodyCategoriesColor:${colors.bodyCategories.color};
                                --bodyCategoriesBackground : ${colors.bodyCategories.background};
                                --categoriesColor: ${colors.categories.color};
                                --categoriesBackground : ${colors.categories.background};
                                --choiceCorrectColor:${colors.choiceCorrect.color};
                                --choiceCorrectBackground: ${colors.choiceCorrect.background};
                                --choiceWrongColor:${colors.choiceWrong.color};
                                --choiceWrongBackground:${colors.choiceWrong.background};
                                --choicesColor:${colors.choices.color};
                                --choicesBackground: ${colors.choices.background};
                                --clearButtonColor: ${colors.clearButton.color};
                                --clearButtonBackground: ${colors.clearButton.background};
                                --headerColor:${colors.header.color};
                                --headerBackground:${colors.header.background};
                                --lampColor:${colors.lamp.color};
                                --starColor:${colors.star.color};
                                --nextPrevQuestionColor:${colors.nextPrevQuestion.color};
                                --lettersSizeMobile:${colors.Letters.sizeMobile};
                                --lettersSizeDesktop:${colors.Letters.sizeDesktop};
                                --casesSizeMobile:${colors.Cases.sizeMobile};
                                --casesSizeDesktop:${colors.Cases.sizeDesktop};
                            }
                        `
                        }]}>
                            <link href={googleFonts.default.link} rel="stylesheet"/>
                            <link href={googleFonts.LettersQuizType1.link} rel="stylesheet"/>
                            <link href={googleFonts.ChoicesQuizType2.link} rel="stylesheet"/>
                        </Helmet>
                        : null
                    }

                    <Header logo={this.props.Esthetics ? this.props.Esthetics.filter(prop=>prop.id==='Logo')[0] : null} auth={this.props.auth}/>
                    <Switch>
                        <Route path="/quizChoices" component={()=><QuizChoices/>} />
                        <Route path="/Categories/:categoryName/:type/:quizIndice" component={Quiz} />
                        <Route exact path="/" component={()=><Categories borders={this.props.Esthetics ? this.props.Esthetics.filter(esthetic=>esthetic.id==='Borders')[0].CategoriesBorders : null} auth={this.props.auth} categories={this.props.Categories}/>} />
                        <Route exact path='/contactUs' component={()=><Contact sendMessage={this.props.sendMessage} closeModal={this.props.closeModalSendMessage} sendMessageStatus={this.props.sendMessageStatus} />} />
                        <Route path="/ads-policy" component={()=><Ads/>} />
                        <Route path="/privacy-policy" component={()=><Privacy/>} />
                    </Switch>
                    <Footer/>
                </React.Fragment>

            )
        }

    }

}


export default withRouter(compose(connect(mapStateToProps , mapDispatchToProps) , firestoreConnect([ {collection: 'Categories'} , {collection: 'Esthetics'}]))(Main));
