import React , {Component} from 'react'
// import logo from "../../assets/img/logo_2.png";
import { withRouter } from 'react-router-dom'
import {User} from './userInfoComponent'
import {Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input , Row , Col , Button} from 'reactstrap'
import {Link} from "react-router-dom";
import AdSense from 'react-adsense';
import  {AdSenseGoogle} from '../AdSenseGoogle/AdSenseGoogle'

class  Header extends Component {
    constructor(props) {
        super(props);
        this.state={
            homeHeader : ['/' , '/privacy-policy'],
        };
    };

    render() {
        const actualPath = this.props.location.pathname;
        const HomeHeader =({auth})=>{
                return(
                    <React.Fragment>
                        <div className='d-flex justify-content-center'>
                            <div className='d-block d-md-none' style={{  position : 'fixed' , top:'65px', width : '320px' , height : '50px'  , zIndex : '1050'}}>
                            {document.documentElement.clientWidth > 768 ? null :<AdSenseGoogle
                                    client='ca-pub-8839630470444144'
                                    slot='9371643354'
                                    styles={{ width: 320 , height: '50px', display: 'inline-block' }}
                                    />
                                    }
                            </div>
                        </div>

                        <nav id='navbarHeader' className="navbar navbar-expand-md fixed-top " style={{height:'65px'}}>
                            <div className="navbar-brand ml-4"><Link to={'/'}><img id="logo" style={ {height: '50px'}} src={this.props.logo.url} alt="Logo"/></Link></div>
                            {/*<User toggleModal={()=>{this.toggleLoginModal()}} auth={auth}/>*/}
                            <User isLoading={auth.isLoading} errMess={auth.errMess}  stars={ auth.data ? auth.data.totalScore : null}/>
                        </nav>
                        {/*<Modal  isOpen={auth.isLoginModalOpen} contentClassName='contentloginModal'>*/}
                        {/*    <ModalHeader ><div style={{textAlign : 'center'}}>Welcome ! </div></ModalHeader>*/}
                        {/*    <ModalBody style={{justifyContent : 'center' }}>*/}
                        {/*        <div className="mb-4" style={{textAlign : 'center' , fontSize : '1.5rem' , fontWeight : '600'}}> Seems like its your first visit into our Site ! </div>*/}
                        {/*        <Form>*/}
                        {/*            <FormGroup className="row align-items-center justify-content-center" >*/}
                        {/*                <Label for='userName' className="col-12 col-lg-3" >User Name :</Label>*/}
                        {/*                <Input className='form-control col-10 col-md-8' bsSize="sm" on  placeholder='your User Name ' innerRef={(input) => this.userName = input} />*/}
                        {/*            </FormGroup>*/}
                        {/*            <FormGroup className='row justify-content-center'>*/}
                        {/*                <Button onClick={()=>{this.submitUser()}}>Submit</Button>*/}
                        {/*            </FormGroup>*/}

                        {/*        </Form>*/}

                        {/*    </ModalBody>*/}
                        {/*</Modal>*/}
                    </React.Fragment>
                )

        };
        const QuizHeader=({auth})=>{
            return (
               <React.Fragment>
                   <div className='d-flex justify-content-center' style={{}}>
                            <div className='d-block d-md-none' style={{ position : 'fixed' , top:'65px', width : '320px' , height : '50px'  , zIndex : '1050'}}>
                            {document.documentElement.clientWidth > 768 ? null :<AdSenseGoogle
                                    client='ca-pub-8839630470444144'
                                    slot='9371643354'
                                    styles={{ width: '320px' , height: '50px' , display: 'inline-block' }}
                                    />
                                    }
                            </div>
                    </div>
                <nav id='navbarHeader' className="navbar  fixed-top" style={{display : 'flex' , justifyContent: 'space-between' , height: '65px'}}>
                    <div className="navbar-brand"><Link to={'/'} style={{color : '#eee'}}><i
                        className="fas fa-arrow-left fa-2x mr-2"/></Link>
                    </div>
                    <div className="navbar-nav" id="QuizLevel">
                        <div style={{color : 'var(--headerColor)'}}>{this.props.location.pathname.split('/')[2] !=null ?  this.props.location.pathname.split('/')[2]+'-Q'+Number(Number(this.props.location.pathname.split('/')[4])+1) : null }</div>
                        {/*<div>Stage 1 -Level 8</div>*/}
                    </div>
                    <ul className="navbar-nav  row  align-items-center">
                        <li className="d-flex nav-item align-items-center mr-2">
                        <p
                            style={{textAlign: 'center' , margin: "0" , color : 'var(--headerColor)' , position : 'relative' , top : '3px'}}>{auth.data ?  auth.data.totalScore :'??'}</p>
                            <i className="fas fa-star ml-2"style={{color : "var(--starColor)" , fontSize : '1.7em'}}/> </li>                    </ul>
                </nav>
               </React.Fragment> 

            )
        };
        if(this.state.homeHeader.indexOf(actualPath)!==-1){
            return(
                <HomeHeader auth={this.props.auth} />
            )
        }else{
            return (
                <QuizHeader auth={this.props.auth} />
            );
        }

    }
}

export default withRouter(Header);