
export const config ={
    apiKey: "AIzaSyByWegTgsAXz8bL09JT4g9yZgAA8Ff3XA4",
    authDomain: "raouf-quiz.firebaseapp.com",
    databaseURL: "https://raouf-quiz.firebaseio.com",
    projectId: "raouf-quiz",
    storageBucket: "raouf-quiz.appspot.com",
    messagingSenderId: "536537570722",
    appId: "1:536537570722:web:551501424dfe095c3ba6fb",
    measurementId: "G-X65F2KDS2G"
};

