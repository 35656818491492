import React, { Component} from 'react';
import PropTypes from 'prop-types'

export  class AdSenseGoogle extends Component {
    static propTypes = {
      client: PropTypes.string,
      slot: PropTypes.string,
      format: PropTypes.string,
      styles: PropTypes.object
    }
    
    componentDidMount() {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    
  
    render() {
      return (
        <div style={this.props.styles} > 
          <ins className="adsbygoogle"  
            style={{'display': 'block' , 'height' : this.props.styles.height , 'width' : this.props.styles.width}}
            data-ad-client={this.props.client}
            data-ad-slot={this.props.slot}
            data-ad-format={this.props.format}>
          </ins>
        </div>
      );
    }
  }