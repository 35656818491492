import React from 'react';
import FailureIcon from "../../json/Failure";
import {ModalBody} from "reactstrap";


export const Failure = (props) => {
    return(
        <React.Fragment>
            <h3 style={{color :'red'}}>{props.errMess}</h3>
            <lottie-player src={JSON.stringify(FailureIcon)} background="transparent"  speed="1"  style={{width: '300px', height: '300px' , justifyContent : 'center' , display : 'contents' }}  autoplay loop/>
        </React.Fragment>
    );
};