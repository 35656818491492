import React, {Component} from 'react';
import {Form, FormGroup , Label , Input , FormFeedback , ModalBody , ModalHeader , Modal } from "reactstrap";
import {Button} from "react-bootstrap";
import {LoadingStatus} from "../statusComponent/loadingComponent";
import {Failure} from "../statusComponent/failureComponent";
import {Success} from "../statusComponent/successComponent";
import { ReCaptcha } from 'react-recaptcha-v3'


class Contact extends Component {
    verifyCallback = (recaptchaToken) => {
		// Here you will get the final recaptchaToken!!!  
		console.log(recaptchaToken, "<= your recaptcha token")
	  }
	
	  updateToken = () => {
		// you will get a new token in verifyCallback
		this.recaptcha.execute();
	  }
    constructor(props) {
        super(props);
            this.state ={
                name : '',
                email : '',
                message : '',
                errors : {
                    name : '',
                    email : '',
                    message : ''
                },
                status : this.props.sendMessageStatus
            }
    }
    handleInputChange=(event)=>{
      this.setState({[event.target.name] : event.target.value})  ;
    };
    ValidateEmail = (mail) => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);

    };
    submit = (event) => {
        let errors={
            email: '',
            message : '',
            name : ''
        };
        console.log('SUBMIT !');
        if(this.state.email.length===0){
            errors.email = 'The email field is required !';
        }else{
            if(!this.ValidateEmail(this.state.email)){
                errors.email = 'Invalid email !';
            }
        }
        if(this.state.message.length===0){
            errors.message = 'the message field is required !';
        }
        if(errors.message==='' && errors.email==='' && errors.name===''){
            this.props.sendMessage(this.state.name, this.state.email, this.state.message);
        }else{
            this.setState({errors: errors});
        }
    };
    render(){
        return (
            <div className=' containerContact justify-content-center col-8' >
                		<ReCaptcha
            				ref={ref => this.recaptcha = ref}
            				sitekey="6LcKwMcZAAAAANfS-XXfr5QWaQrmPEZhmrhijOZt"
            				action='recaptcha-contact'
            				verifyCallback={this.verifyCallback}
        				/>
                <div className='mt-4 justify-content-center'>
                    <h1 style={{textAlign : 'center'}}>Contact us</h1>
                </div>
                <div className=' justify-content-center'>
                    <h2>How can we help ?</h2>
                </div>
                <Form className="row" id='contactForm' >
                    <FormGroup className='offset-1 col-5 contactFormGroup'>
                        <Label htmlFor='name' className='ml-2'> Name </Label>
                        <Input style={{opacity : this.state.name==='' ? '.7' : '1'}} invalid={this.state.errors.name!==''} name='name'  type='text' className='form-control' placeholder='Full Name' onChange={(event)=>this.handleInputChange(event)} value={this.state.name}/>
                        <FormFeedback>{this.state.errors.name}</FormFeedback>
                    </FormGroup>
                    <FormGroup className='col-5 contactFormGroup'>
                        <Label  htmlFor='email' className='ml-2'> Email </Label>
                        <Input style={{opacity : this.state.email==='' ? '.7' : '1'}} invalid={this.state.errors.email!==''} name='email' type='email' className='form-control' placeholder='Email' onChange={(event)=>this.handleInputChange(event)} value={this.state.email}/>
                        <FormFeedback>{this.state.errors.email}</FormFeedback>
                    </FormGroup>
                    <FormGroup className='offset-1 col-10 row justify-content-center'>
                        <Label htmlFor='message' className='ml-2'>Your message</Label>
                        <Input style={{opacity : this.state.message==='' ? '.7' : '1'}} invalid={this.state.errors.message !==''}  name='message'  type='textarea'  htmlFor='message' className='form-control' rows={7} value={this.state.message} onChange={(event)=>this.handleInputChange(event)}/>
                        <FormFeedback>{this.state.errors.message}</FormFeedback>
                        <Button id='submitContact' onClick={(event)=>this.submit(event)} className="mt-3" >Submit</Button>
                    </FormGroup>

                </Form>
                <Modal toggle={this.state.status.done ? this.props.closeModal :null} isOpen={this.state.status.isLoading || this.state.status.errMess || this.state.status.done}>
                    <ModalHeader toggle={this.state.status.done ? this.props.closeModal :null}><h2 style={{color : 'black'}}>{this.state.status.isLoading ? 'Please Wait' : this.state.status.errMess ? 'Failure !' : 'Success !'}</h2></ModalHeader>
                    <ModalBody style={{textAlign : 'center'}}>
                        {/*<Success/>*/}
                        {/*<Failure/>*/}
                        {this.state.status.isLoading ? <LoadingStatus/> : this.state.status.errMess ? <Failure errMess={this.state.status.errMess}/> : <Success message={true}/>}
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default Contact;