import avatar from "../../assets/img/avatar_1.png";
import React from "react";
import './styles.css'
import {Loading} from "../loadingComponent";


export const User= ({ stars , isLoading , errMess})=>{
        if(errMess){
            return <div>{errMess}</div>
        }else if (isLoading){
            return <Loading/>
        }else if( stars!==null){
            return(
                <ul className="navbar-nav ml-auto row  align-items-center d-flex">
                    <p
                        style={{textAlign: 'center' , margin: "0" , color : 'var(--headerColor)'}}>{stars}</p>
                    <li className="nav-item m-2 mr-3"><i className="fas fa-star"
                                                                     style={{color : "var(--starColor)" , fontSize : '1.7em'}}/> </li>
                    {/*<li className="nav-item m-2 mr-lg-4 ml-lg-4 "><a href="https://google.com" className="nav-link" style={{color:"#eee"}}>*/}
                    {/*    <div id="avatar"><img src={avatar} alt="avatar"/><span*/}
                    {/*        className="d-none d-lg-inline ml-1">{userName != null ? userName : '?????'}</span></div>*/}
                    {/*</a></li>*/}
                </ul>
            );
        }else{
            return null;
        }

};
