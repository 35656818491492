
import React , {Component} from 'react';

class Ads extends Component {
    render(){
        return (
           <div className='containerAds' style={{width : '80vw' , marginLeft:'10vw'}}>
                <div className='mt-4 justify-content-center'>
                    <h1 className='mb-5' style={{textAlign : 'center'}}>Ads policy</h1>
                </div>
                <div class="entry-content page-content">
		
<p>Relrules is one of the biggest brands in its niche and part of Dutch media company&nbsp;<a rel="noreferrer noopener" href="https://www.weblog-media.com" target="_blank">WeBlog Media</a>. In only a short period of time, our platform’s reach has virtually exploded. Because of this, both our website and our popular <a href="https://www.facebook.com/pg/RelationshipRules">Facebook page</a> gets thousands of visitors every single day. Relrules knows how to surprise hundreds of thousands of visitors every day with contemporary articles on topics like health, lifestyle, <a href="https://xjobs.org/" >найти работу екатеринбург</a>  entertainment, lifehacks and everything in and around the house.</p>



<p>Relrules does not just present a nice environment for readers, but also for advertisers. We offer advertisers multiple different possibilities to show their campaigns to our large audience. For example, it is possible to post on-topic advertorials (written by our own editing team if you so wish) or to buy advertising space on the website. Besides this, our in-house content marketers offer the unique opportunity to go big with an advertising campaign among our visitors completely tailored to the advertiser’s <a href="https://igrovyieavtomatibesplatno.com/">игровые автоматы онлайн играть бесплатно</a>  wishes. Do you want to know more about our advertising possibilities and corresponding rates? Fill in the <a href="http://relrules.us/cookie-policy/">contact form</a> and we will try to answer any questions you might have within two working days.</p>
	</div>
           </div>     

        )
    }
}

export default Ads;