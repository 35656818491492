import * as ACTIONTYPES from './ActionTypes';
import {getFirestore} from "redux-firestore";
import {getFirebase} from "react-redux-firebase";
import {Categories} from './configureStore'

export const loadingUser = ()=>{
    return {
        type : ACTIONTYPES.LOADING_USER
    }
};

export const getUser =(data)=>{
    return {
        type : ACTIONTYPES.GET_USER,
        payload : data
    }
};
export const failedUser=(errMess)=>{
    return {
        type : ACTIONTYPES.USER_FAILED,
        payload : errMess

    }
};

export const loadUser = ()=>(dispatch , getState)=>{
    try{
        let data = JSON.parse(sessionStorage.getItem('Auth'));
        if(data!=null){
            dispatch(getUser(data));
        }else{
            let Categories =getState().firestore.ordered.Categories;
            if(Categories==null){
                console.log('LOAD USER : NULL');
                dispatch(loadingUser());
                dispatch(loadingUser());
            }else{
                data = {};
                data.totalScore = 0;
                Categories.forEach(category=>{
                    let categoryname = category.categoryName;
                    let quizdata = {};
                    category.quizzes.forEach((quiz,index)=>{
                        if(quiz.type===1){
                            const answer = quiz.answer;
                            const lettersArr = answer.split('');
                            const nbr_letters = 16;
                            const validPositions = [];
                            const lettersCoord =[];
                            lettersArr.forEach(letter=>{
                                if(letter!==' '){
                                    var rightPosition = Math.floor(Math.random()*16);
                                    while(validPositions.indexOf(rightPosition) !==-1){
                                        rightPosition = Math.floor(Math.random()*16);
                                    }
                                    validPositions.push(rightPosition);
                                    lettersCoord.push({letter : letter , position: rightPosition})
                                    console.log(validPositions);
                                }
                            });
                            console.log(lettersCoord);
                            const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                            const finalLettersArr =[];
                            for (let i = 0; i < 16; i++) {
                                const indice = validPositions.indexOf(i);
                                if(indice===-1){
                                    const randomLetter=alphabet.charAt(Math.floor(Math.random() * alphabet.length));
                                    finalLettersArr.push(randomLetter);
                                }else{
                                    finalLettersArr.push(lettersCoord[indice].letter);
                                }
                            }
                            quizdata= {...quizdata ,['q'+index] : {letters : finalLettersArr , strictCorrect : [] , strictLettersLessOpacity : [] }}
                        }else{
                            quizdata={...quizdata , ['q'+index] : {strictWrong : [] , order: [] , tried : false}}
                        }
                        console.log('quizdata=', quizdata);
                    })
                    data= {...data , [categoryname]: {score: 0 , help :10 , solved: [] , quizdata : quizdata}};

                });
                let sortByOrd=(obj1 , obj2)=>{
                    if(obj1.ord>obj2.ord){
                        return 1
                    }
                    if(obj1.ord<obj2.ord){
                        return -1;
                    }
                    return 0;
            
                };
                console.log("DATA NEW =" ,data);
                sessionStorage.setItem('Auth' , JSON.stringify(data));
                sessionStorage.setItem('Categories', JSON.stringify(Categories));
                sessionStorage.setItem('pub', '0');
                sessionStorage.setItem('AnswerPopUpPub' , '1');
                dispatch(getUser(data));
            }
        }
    }catch (error) {
        dispatch(failedUser(error.message));
    }

};

export const callIncHelp=(categoryName , numQuiz)=>(dispatch , getState)=>{
    let Categories =getState().firestore.ordered.Categories;
    let answer = Categories.filter(category => category.categoryName === categoryName)[0].quizzes[numQuiz].answer;
    dispatch(incHelp(categoryName, numQuiz, answer));
};

 const incHelp =(categoryName , numQuiz , answer)=>{
    return {
        type : ACTIONTYPES.INC_HELP,
        payload:
            {
                categoryName : categoryName,
                numQuiz : numQuiz,
                answer : answer
            }
    }
};

export const callDecHelp = (categoryName ,numQuiz , ...indices)=>(dispatch, getState)=>{
    let Categories =getState().firestore.ordered.Categories;
    let letters = [];
    console.log('CATEGORIES ARE : ', Categories);
    indices.forEach(indice=>{
        console.log('categoryName is : ', categoryName);
        console.log('first category name : is ', Categories[0].categoryName);
        letters.push(Categories.filter(category => category.categoryName === categoryName)[0].quizzes[numQuiz].answer[indice]);
    });
    console.log('letters = ', letters);
    console.log('DEC HELP !');
    dispatch(dechelp(categoryName , numQuiz , letters , indices))
};

const dechelp = (categoryName ,numQuiz , letters , indices)=>{
        return {
            type : ACTIONTYPES.DEC_HELP,
            payload : {
                categoryName : categoryName ,
                numQuiz : numQuiz,
                indices : indices,
                letters : letters
            }
        }
};

// ACTIONS FOR QUIZ  CHOICES COMPONENT

export const decHelp2 = (categoryName , numQuiz , wrong , order )=>{
    return {
        type : ACTIONTYPES.DEC_HELP2,
        payload : {
          categoryName: categoryName ,
           numQuiz : numQuiz ,
           wrong : wrong,
            order : order

        }
    }
};

export const setOrderChoices=(categoryName , numQuiz , order)=>{
    return{
        type:ACTIONTYPES.SET_ORDER_CHOICES,
        payload : {
            categoryName : categoryName,
            numQuiz : numQuiz,
            order: order
        }
    }

};

export const triedChoice=(categoryName , numQuiz , correct)=>{
    return {
        type : ACTIONTYPES.TRIED_CHOICE,
        payload : {
            categoryName : categoryName ,
            numQuiz : numQuiz ,
            correct : correct
        }
    }
};

export const sendMessage =(name , email , message)=>(dispatch, getState, {getFirestore, getFirebase} )=>{
    let firestore = getFirestore();
    dispatch(loadingSendMessage());
    let messages = null;
    firestore.collection('Messages').doc('Messages').get().then(doc => {
        messages = doc.data().messages;
        let newMessage = {
            createdAt: new Date().toString(),
            name: name,
            email: email,
            message: message
        };
        messages.unshift(newMessage);
        return firestore.collection('Messages').doc('Messages').update({messages : messages})
    }).then(()=>{
        dispatch(doneSendMessage());
    }).catch(err=>{
        dispatch(errSendMessage(err.message));
    })
};


export const loadingSendMessage = () => {
    return {
        type: ACTIONTYPES.LOADING_SEND_MESSAGE
    }
};

export const doneSendMessage = ()=>{
    return {
        type: ACTIONTYPES.DONE_SEND_MESSAGE
    }
};
export const errSendMessage = (errMess) => {
    return {
        type: ACTIONTYPES.ERR_SEND_MESSAGE,
        payload: errMess
    }
};

export const closeModalSendMessage = () => {
    return {
        type: ACTIONTYPES.CLOSE_MODAL_SEND_MESSAGE
    }
};



// export const handleUser =()=>(dispatch , {getFirestore , getFirebase})=>{
//     if(localStorage.getItem('userName')!=null){
//         console.log('old user', localStorage.getItem('userName'));
//         dispatch(getUser());
//     }else{
//         setTimeout(() => {
//             console.log('new user !!');
//             dispatch(openLoginUser());
//         }, 1000);
//
//     }
// };


// add Element in FireStore

// export const fetchQuiz = (quiz)=>(dispatch , {getFirestore , getFirebase})=>{
//     const firestore = getFirestore();
//     firestore.collection('quizzes').add({...quiz , createdAt : new Date()})
//     .then(quiz => dispatch(addQuiz(quiz)))
//     .catch(err=> dispatch (errorQuiz(err)));
// };