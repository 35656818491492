
import React , {Component} from 'react';
import { ReCaptcha } from 'react-recaptcha-v3'

class Privacy extends Component {
	verifyCallback = (recaptchaToken) => {
		// Here you will get the final recaptchaToken!!!  
		console.log(recaptchaToken, "<= your recaptcha token")
	  }
	
	  updateToken = () => {
		// you will get a new token in verifyCallback
		this.recaptcha.execute();
	  }
    render(){
        return (
        
            <div id="PrivacypolicyContainer" className="entry-content page-content">
				        <ReCaptcha
            				ref={ref => this.recaptcha = ref}
            				sitekey="6LcKwMcZAAAAANfS-XXfr5QWaQrmPEZhmrhijOZt"
            				action='recaptcha-privacy'
            				verifyCallback={this.verifyCallback}
        				/>
				<button className="g-recaptcha" 
        data-sitekey="reCAPTCHA_site_key" 
        data-callback='onSubmit' 
        data-action='submit'>Submit</button>
                <h1 className='mb-4' id='privacyPolicyTitle'>Privacy Policy </h1>
		<div id="tps_slideContainer_3" className="theiaPostSlider_slides"><div className="theiaPostSlider_preloadedSlide">

<div data-elementor-type="wp-page" data-elementor-id="3" className="elementor elementor-3" data-elementor-settings="[]">
						<div className="elementor-inner">
							<div className="elementor-section-wrap">
							<section className="elementor-section elementor-top-section elementor-element elementor-element-b6dd3d8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="b6dd3d8" data-element_type="section">
						<div className="elementor-container elementor-column-gap-default">
							<div className="elementor-row">
					<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bc7fae8" data-id="bc7fae8" data-element_type="column">
			<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
						<div className="elementor-element elementor-element-5cff24a elementor-widget elementor-widget-text-editor" data-id="5cff24a" data-element_type="widget" data-widget_type="text-editor.default">
				<div className="elementor-widget-container">
					<div className="elementor-text-editor elementor-clearfix"><p><strong>Comments</strong></p>
<p>When you leave a comment on our website, the data entered in the comment form, as well as your IP address and your browser user agent is collected to help us detect spam comments.</p>
<p>An anonymous string created from your email address (also known as a hash) can be sent to the Gravatar service to verify if you are using it. The confidentiality clauses of the Gravatar service are available at this link: https://automattic.com/privacy/. After validating your comment, your profile picture will be publicly visible next to your comment.</p>
<p><strong>Cookies</strong></p>
<p>If you post a comment on our site, you will be offered to save your name, email address and website in cookies. This is just for your convenience so that you don’t have to re-enter this information if you post another comment later. These cookies expire after one year.</p>
<p>If you log in from your page, a temporary cookie will be created to determine whether your browser accepts cookies or not. It will never contain personal data and will be automatically deleted when you close your browser.</p>
<p>When you log in, we will set up a series of cookies to store your login information and screen preferences. The retention period of a connection cookie is two days, that of a display option cookie is one year. If you check “Remember me”, your login cookie will remain for two weeks. If you log out of your account, the login cookie will be deleted.</p>
<p>When editing or publishing a post, an additional cookie will be saved in your browser. This cookie does not include any personal data. It just shows the ID of the post you just edited. It expires after one day.</p>
<p><strong>Embedded content from other sites</strong></p>
<p>Articles on this site may include embedded content (eg, videos, images, articles, etc.). Embedded content from other sites behaves the same as if the visitor were to that other site.</p>
<p>These websites may collect data about you, use cookies, integrate third-party tracking tools, track your interactions with this embedded content if you have an account logged into your website.</p>
<p><strong>Configure cookies and web beacons</strong></p>
<p>For more information on the methods that allow you to delete and control the cookies stored on your computer, see the following site: http://www.allaboutcookies.org/en/</p>
<p><strong>How to configure your browser</strong><br/><span style={{textDecoration: 'underline'}}><strong>Firefox</strong></span></p>
<p>1. Open Firefox<br/>2. Press the “Alt” key<br/>3. In the menu at the top of the page, click on “Tools” then on “Options”<br/>4. Select the “Confidentiality” tab<br/>5. In the drop-down menu to the right of “Storage rules”, click “Use custom settings for history”<br/>6. A little further down, uncheck “Accept cookies”<br/>7. Save your preferences by clicking “OK”</p>
<p><span style={{textDecoration: 'underline'}}><strong>Internet Explorer:</strong></span></p>
<p>1. Open Internet Explorer<br/>2. In the “Tools” menu, select “Internet Options”<br/>3. Click on the “Confidentiality” tab<br/>4. Click on “Advanced” and uncheck “OK”<br/>5. Save your preferences by clicking on “OK”</p>
<p><span style={{textDecoration: 'underline'}}><strong>Google Chrome:</strong></span></p>
<p>1. Open Google Chrome<br/>2. Click on the tool icon in the menu bar.<br/>3. Select “Options”<br/>4. Click on the “Advanced options” tab<br/>5. In the “Cookie settings” drop-down menu, select “Block all cookies”</p>
<p><span style={{textDecoration: 'underline'}}><strong>Safari:</strong></span></p>
<p>1. Open Safari<br/>2. In the menu bar at the top, click on “Safari”, then on “Preferences”<br/>3. Select the “Security” icon<br/>4. Next to “Accept cookies”, check “Never”<br/>5. If you want to see the cookies already stored on your computer, click on “Show cookies”</p>
<p>Please note that this deactivation does not affect the display of advertisements published by other partners and does not affect the areas of interest that you expressly communicate.</p></div>
				</div>
				</div>
						</div>
					</div>
		</div>
								</div>
					</div>
		</section>
						</div>
						</div>
					</div>
</div></div></div>
        )
    }
}

export default Privacy;