
import './styles.css'
import React , {Component} from 'react'
import {firestoreConnect} from "react-redux-firebase";
import logo from '../../assets/img/logo_2.png'
import avatar from '../../assets/img/avatar_1.png'
// import {Loading} from "../loadingComponent";

import  {compose} from "redux";
import {connect} from "react-redux";
import {Loading} from "../loadingComponent";
import {Link} from "react-router-dom";
import badge from '../../assets/img/medal.svg'
import { ReCaptcha } from 'react-recaptcha-v3'



class Categories extends Component {

    constructor(props) {
        super(props);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('compoenent updated !, categories = ', this.props.categories);

    }
    verifyCallback = (recaptchaToken) => {
		// Here you will get the final recaptchaToken!!!  
		console.log(recaptchaToken, "<= your recaptcha token")
	  }
	
	  updateToken = () => {
		// you will get a new token in verifyCallback
		this.recaptcha.execute();
	  }

    render(){
        // var style = null;
        // if(this.props.borders){
        //     style = { "--CategoriesBorders": 'solid '+this.props.borders.borderColor+ ' '+this.props.borders.borderWidth , "--CategoriesRadiusBorders" : this.props.borders.borderRadius } ;
        // }


        console.log('RENDER !');
            let Category = ({ howManyQuiz , scoreCategory , typeQuiz1 , name , icon })=>{
                console.log('Category rendered !');
                return (
                        <Link to={'/Categories/'+name+'/'+typeQuiz1+'/0'}   className="category col-5 col-lg-3 p-3 m-lg-3 m-1">
                            {scoreCategory===howManyQuiz ? <img src={badge} className='badgeIcon' alt=""/> : null}
                            <img style={{height : '100px' , width : '100px'}} src={icon} alt={'category Icon'}/>
                            <p className="categoryName">{name}</p>
                            <p className="actualScore"> Actual score : <br className="d-lg-none"/> {scoreCategory}/{howManyQuiz} <i
                                className="fa fa-star" style={{color : "darkorange"}}/></p>
                        </Link>

                )
            };
            let TheCategories = null;
            if(this.props.categories && this.props.auth.data){
                const sortByOrd=(obj1 , obj2)=>{
                    if(obj1.ord>obj2.ord){
                        return 1
                    }
                    if(obj1.ord<obj2.ord){
                        return -1;
                    }
                    return 0;
        
                };
                console.log('THE CATEGORIES = ' , this.props.categories.slice().sort(sortByOrd));
                TheCategories = this.props.categories.slice().sort(sortByOrd).map((category,index) => {
                    console.log(category.categoryName);
                    return(
                        <Category howManyQuiz={category.quizzes.length} scoreCategory={this.props.auth.data[category.categoryName].score} typeQuiz1={category.quizzes[0].type} key={index} name={category.categoryName} icon={category.icon}/>
                    )
                });
            }
            console.log('Categories Compoennet =', TheCategories);
            return (
                <React.Fragment>
                    <div className="container mb-5" style={{width : '100vw' , display : 'block'}}>
                    <ReCaptcha
            				ref={ref => this.recaptcha = ref}
            				sitekey="6LcKwMcZAAAAANfS-XXfr5QWaQrmPEZhmrhijOZt"
            				action='recaptcha-home'
            				verifyCallback={this.verifyCallback}
        				/>
                        <div id="Categories mt-2">
                            <h2  style={{ marginTop  : '4rem'}}>Choose a category to start : </h2>
                            <div className="row justify-content-center">
                                {TheCategories!= null && this.props.auth.data!=null ? TheCategories : <Loading/>}
                            </div>
                        </div>



                        {/*<div className="row justify-content-center mt-4">*/}
                        {/*    <a className="btn-lg m-2" id="backHomePage" href="https://google.com"><i className="fa fa-arrow-left"/> Back to Home Page*/}
                        {/*    </a>*/}
                        {/*    <a className="btn-lg m-2 " id="backHomePage" href="https://google.com"><i*/}
                        {/*        className="fa fa-arrow-left"/> Guest Account</a>*/}
                        {/*    <a className="btn-lg m-2" id="backHomePage" href="https://google.com"><i*/}
                        {/*        className="fa fa-arrow-left"/> Deconnected User </a>*/}
                        {/*    <a className="btn-lg m-2" id="backHomePage" href="https://google.com" ><i*/}
                        {/*        className="fa fa-arrow-left"/> Go to quiz page </a>*/}
                        {/*    <a className="btn-lg m-2" id="backHomePage" href="https://google.com" ><i*/}
                        {/*        className="fa fa-arrow-left"/> NEW ! Go to Stage page </a>*/}
                        {/*    <a className="btn-lg m-2" id="backHomePage" href="https://google.com" ><i*/}
                        {/*        className="fa fa-arrow-left"/> EXTRA NEW ! Go to QUIZ TYPE 2 ! </a>*/}
                        {/*</div>*/}

                    </div>
                </React.Fragment>

            )

    }
}

// we need categories collection in our categories compoenent
export default Categories;