import React from 'react';
import successIcon from "../../json/Success";
import {ModalBody} from "reactstrap";


export const Success = (props) => {
    if(!props.message){
        return(
            <React.Fragment>
                <h3 style={{color :'green'}}>The {props.quiz ? 'quiz' : 'Category'}  is {props.modify ? 'modified' : props.delete ? 'deleted' : 'added' } Successfully !</h3>
                <lottie-player src={JSON.stringify(successIcon)} background="transparent"  speed="1.5"  style={{width: '300px', height: '300px' , justifyContent : 'center' , display : 'contents' }}  autoplay loop/>
            </React.Fragment>
        );
    }else{
        return(
            <React.Fragment>
                <h3 style={{color :'green'}}>Your message is sentSuccessfully !</h3>
                <lottie-player src={JSON.stringify(successIcon)} background="transparent"  speed="1.5"  style={{width: '300px', height: '300px' , justifyContent : 'center' , display : 'contents' }}  autoplay loop/>
            </React.Fragment>
        );
    }


};