import './styles.css'
import React , {Component} from "react"
import img from '../../assets/img/tiger.jpg'
import {Modal , ModalHeader , ModalBody , ModalFooter , Button} from "reactstrap"
import {Link} from "react-router-dom";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import {Loading} from "../loadingComponent";
import AdSense from 'react-adsense';
import {AdSenseGoogle} from '../AdSenseGoogle/AdSenseGoogle'
import { ReCaptcha } from 'react-recaptcha-v3'



const Letters= ({ lettersBorder ,  strictLettersLessOpacity ,answer , letterClicked , availableLetters , setAvailableLetters})=>{
    let letters;
    var style = null;
    if(lettersBorder){
        style = { "--LettersBorder": 'solid '+lettersBorder.borderColor+ ' '+lettersBorder.borderWidth , "--LettersBorderRadius" : lettersBorder.borderRadius } ;
    }
    if(availableLetters.length ===0){
        const lettersArr = answer.split('');
        const nbr_letters = 16;
        const validPositions = [];
        const lettersCoord =[];
        lettersArr.forEach(letter=>{
            if(letter!==' '){
                var rightPosition = Math.floor(Math.random()*16);
                while(validPositions.indexOf(rightPosition) !==-1){
                    rightPosition = Math.floor(Math.random()*16);
                }
                validPositions.push(rightPosition);
                lettersCoord.push({letter : letter , position: rightPosition})
                console.log(validPositions);
            }
        });
        console.log(lettersCoord);
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const finalLettersArr =[];
        for (let i = 0; i < 16; i++) {
            const indice = validPositions.indexOf(i);
            if(indice===-1){
                const randomLetter=alphabet.charAt(Math.floor(Math.random() * alphabet.length));
                finalLettersArr.push(randomLetter);
            }else{
                finalLettersArr.push(lettersCoord[indice].letter);
            }
        }
        setAvailableLetters(finalLettersArr);
        letters = finalLettersArr.map((letter , index)=>{
            return (<div className="btn letter" key={index} onClick={(event)=>{letterClicked(event)}}>{letter}</div>);
        });
    }else{
        let removed = {};
        letters= availableLetters.map((letter, index)=>{
            if(strictLettersLessOpacity.indexOf(letter)===-1 || removed[letter]!=null){
                return (<div style={style} className="btn letter" indice={index} key={index} onClick={(event)=>{letterClicked(event)}} >{letter}</div>);
            }else{
                removed={...removed , [letter]: true};
                return (<div style={style} className="btn letter lessOpacity" indice={index} key={index} onClick={(event)=>{letterClicked(event)}} >{letter}</div>);

            }
        })
    }

    return(
        <React.Fragment>
            {letters}
        </React.Fragment>
    )
};


class QuizLetters extends Component {
    verifyCallback = (recaptchaToken) => {
		// Here you will get the final recaptchaToken!!!  
		console.log(recaptchaToken, "<= your recaptcha token")
	  }
	
	  updateToken = () => {
		// you will get a new token in verifyCallback
		this.recaptcha.execute();
	  }
    constructor(props) {
        super(props);
        this.state= {
            image : this.props.quiz.image,
            answer : this.props.quiz.answer,
            userEssay : '',
            letters : this.props.letters,
            lettersLower : this.props.lettersLower,
            strictCaseCorrect : this.props.strictCorrect,
            strictLettersLessOpacity: this.props.strictLetterslessOpacity,
            isCorrectModalOpen : false ,
            isWrongModalOpen : false,
            isHelpModalOpen: false,
            availableHelps: this.props.help,
            solved : this.props.solved,
            indice1 : null ,
            indice2 : null ,
            indice3 : null,
            isPubModalOpen: false,
            pub : '<script>console.log("Hello world");</script>'
        };
        console.log('QUIZ IS :', this.props.quiz);
    }
    toggleCorrectModal=()=>{
        console.log('TOGGLE CORRECT !');
        let popUpPub=Number(sessionStorage.getItem('AnswerPopUpPub'));
        if(document.documentElement.clientWidth >729){
            sessionStorage.setItem('AnswerPopUpPub' , (popUpPub+1).toString());
        }
        this.props.callIncHelp(this.props.category , this.props.indice);
        this.setState({...this.state , isCorrectModalOpen : !this.state.isCorrectModalOpen});
    };
    toggleWrongModal=()=>{
        let popUpPub=Number(sessionStorage.getItem('AnswerPopUpPub'));
        if(document.documentElement.clientWidth >729){
            sessionStorage.setItem('AnswerPopUpPub' , (popUpPub+1).toString());
        }
        this.setState({...this.state , isWrongModalOpen : !this.state.isWrongModalOpen})
    };
    toggleHelpModal=()=>{
        console.log('Toggle help is called ! , indice1 :', this.state.indice1, 'indice2 :', this.state.indice2);
        if(this.state.indice3!==null){
            this.props.callDecHelp(this.props.category , this.props.indice , this.state.indice1 , this.state.indice2 , this.state.indice3);
        }
        else if(this.state.indice2 !==null){
            this.props.callDecHelp(this.props.category , this.props.indice , this.state.indice1 , this.state.indice2);
        }else{
            this.props.callDecHelp(this.props.category , this.props.indice ,  this.state.indice1 );
        }
        this.setState({...this.state , isHelpModalOpen : !this.state.isHelpModalOpen });
    };
    togglePubModal =()=>{
        if(this.state.solved){
            this.setState({...this.state , isPubModalOpen : !this.state.isPubModalOpen , userEssay : this.props.quiz.answer }); 
        }else{
            this.setState({...this.state , isPubModalOpen : !this.state.isPubModalOpen }); 
        }
        
    };
    openHelpModal = ()=>{
        if(this.state.availableHelps >0 && !this.state.solved){
            if(this.state.availableHelps===1){
                console.log('hide the icon !');
            }
            let notCorrect = document.querySelectorAll('.case:not(.space):not(.caseCorrect)');
            let correct1 = Math.floor(Math.random() * notCorrect.length);
            while( this.state.strictCaseCorrect.indexOf(Number(notCorrect[correct1].getAttribute('indice')))!==-1){
                correct1 = Math.floor(Math.random() * notCorrect.length);
            }
            let correct2=null;
            if(notCorrect.length>1){
                correct2 = Math.floor(Math.random() * notCorrect.length);
                while( correct1 ===correct2 || this.state.strictCaseCorrect.indexOf(Number(notCorrect[correct1].getAttribute('indice')))!==-1){
                    correct2 = Math.floor(Math.random() * notCorrect.length);
                }
            }
            let correct3 = null;
            if(this.state.answer.split(' ').join('').length>7 && notCorrect.length > 2){
                correct3 = Math.floor(Math.random() * notCorrect.length);
                while( correct3 ===correct1 || correct3===correct2 || this.state.strictCaseCorrect.indexOf(Number(notCorrect[correct1].getAttribute('indice')))!==-1){
                    correct3 = Math.floor(Math.random() * notCorrect.length);
                }
            }
            console.log('we will correct : ', notCorrect[correct1], notCorrect[correct2] , notCorrect[correct3]);
            let indice1 = Number(notCorrect[correct1].getAttribute('indice'));
            let indice2 = correct2==null ? null : Number(notCorrect[correct2].getAttribute('indice'));
            let indice3 = correct3==null ? null : Number(notCorrect[correct3].getAttribute('indice'));
            notCorrect[correct1].classList.add('caseCorrect');
            notCorrect[correct1].classList.remove('caseEmpty');
            console.log('i will help you with : ', this.state.answer[indice1]);
            let removed1=false;
            document.querySelectorAll('.letter:not(.lessOpacity)').forEach(Letter=>{
                    if(Letter.innerText===this.state.answer[indice1] && !removed1){
                        Letter.classList.add('lessOpacity');
                        removed1 = true;
                    }
                }
            );
            notCorrect[correct1].innerText = this.state.answer[indice1];
            if(correct2!=null){
                let removed2 = false;
                notCorrect[correct2].classList.add('caseCorrect');
                notCorrect[correct2].classList.remove('caseEmpty');
                console.log('i will help you with : ', this.state.answer[indice1]);
                notCorrect[correct2].innerText = this.state.answer[indice2];
                document.querySelectorAll('.letter:not(.lessOpacity)').forEach(Letter=>{
                        if(Letter.innerText===this.state.answer[indice2] && !removed2){
                            Letter.classList.add('lessOpacity');
                            removed2 = true;
                        }
                    }
                );
            }
            if(correct3 !=null){
                let removed3 = false;
                notCorrect[correct3].classList.add('caseCorrect');
                notCorrect[correct3].classList.remove('caseEmpty');
                console.log('i will help you with : ', this.state.answer[indice3]);
                notCorrect[correct3].innerText = this.state.answer[indice3];
                document.querySelectorAll('.letter:not(.lessOpacity)').forEach(Letter=>{
                        if(Letter.innerText===this.state.answer[indice3] && !removed3){
                            Letter.classList.add('lessOpacity');
                            removed3 = true;
                        }
                    }
                );
            }
            if(correct3!=null){
                let newStricte = this.state.strictCaseCorrect;
                newStricte.push(indice1 , indice2 , indice3);
                // this.props.callDecHelp(this.props.category , this.props.indice ,  indice1 , indice2);
                this.setState({...this.state, isHelpModalOpen: true , indice1 : indice1 , indice2 : indice2 , indice3 :indice3 });
            }
            else if(correct2 != null ){
                let newStricte = this.state.strictCaseCorrect;
                newStricte.push(indice1 , indice2);
                // this.props.callDecHelp(this.props.category , this.props.indice ,  indice1 , indice2);
                this.setState({...this.state, isHelpModalOpen: true , indice1 : indice1 , indice2 : indice2 , indice3:null });
            }else{
                let newStricte = this.state.strictCaseCorrect;
                newStricte.push(indice1  );
                // this.props.callDecHelp(this.props.category , indice1);
                this.setState({...this.state, isHelpModalOpen: true , indice1 : indice1 , indice2 : null , indice3 :null});
            }


        }
    };
    userAnswer=()=>{
        let answer = '';
        let stop=false;
        console.log('USER ANSWER CASES :', document.querySelectorAll('[indice]:not(.letter)'));
        document.querySelectorAll('[indice]:not(.letter)').forEach(Case=>{
            if(!stop){
                if(Case.classList.contains('space')){
                    answer += ' ';
                }else{
                    if(Case.innerText!=='-')
                        answer += Case.innerText;
                    else
                        stop=true;
                }
            }
        });
        return answer;
    };
    componentDidMount() {
        let numbPub=Number(sessionStorage.getItem('pub'));
        if(numbPub%3===0 && numbPub!==0){
            this.togglePubModal();
            sessionStorage.setItem('pub', '0');
        }
        if(this.state.solved){
            document.querySelectorAll('.case').forEach((Case,index) => {
                Case.classList.add('caseCorrect');
                Case.innerText = this.state.answer.charAt(Number(Case.getAttribute('indice')));
                Case.classList.remove('caseEmpty');
            });
        }
    }

    componentWillUnmount() {
        console.log('LEFT');
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('UPDATED');
        // When the quiz is solved we mark all the cases as solved cases
        if(this.state.solved){
            document.querySelectorAll('.case').forEach((Case,index) => {
                Case.classList.add('caseCorrect');
            });
        }
        if(!this.state.solved && document.querySelectorAll('.case:not(.caseCorrect)').length ===0  ){
            // this.props.callIncHelp(this.props.category , this.props.indice);
            this.setState({...this.state , solved: true , isCorrectModalOpen : true  });
        }
        // this.setState({...this.state, userEssay: this.userAnswer()});
        if (!this.state.solved && document.querySelectorAll('.caseEmpty').length === 0 ) {
            console.log(' all the cases are filled ! , the answer now is :', this.userAnswer());
            if (this.userAnswer() === this.state.answer) {
                this.setState({...this.state, isCorrectModalOpen: true , solved:true  });
                // this.props.callIncHelp(this.props.category , this.props.indice);
            } else {
                document.querySelector('#cases').classList.add('animate__animated', 'animate__headShake');
                document.querySelector('#cases').style.setProperty('--animate-duration', '700ms');
                setTimeout(() => {
                    document.querySelector('#cases').classList.remove('animate__animated', 'animate__headShake');
                    document.querySelectorAll('.lessOpacity').forEach(letter => {
                        letter.classList.remove('lessOpacity');
                    });
                    this.setState({...this.state, userEssay: '', isWrongModalOpen: true });
                }, 700);
            }
        }
    }
    prevNextClicked=()=>{
        let numbPub = Number(sessionStorage.getItem('pub'));
        sessionStorage.setItem('pub' , (numbPub+1).toString());
    }

    render(){
        const Cases = ({ casesBorder , answer , userEssay , caseClicked , strictCaseCorrect })=>{
            let appearedAnswerArr = answer.split('');
            const nbr_cases = answer.length;
            const taille_max = 10;
            let FinalCases;
            // const vide_gauche = Math.floor((taille_max - nbr_cases) / 2);
            // const vide_droite = taille_max - nbr_cases - vide_gauche;
            // for (let i = 1; i <= vide_gauche; i++) {
            //     appearedAnswerArr.unshift(' ');
            // }
            // for (let i = 1; i <= vide_droite; i++) {
            //     appearedAnswerArr.push(' ');
            // }
            console.log('appeared Answer : ', appearedAnswerArr);
            let decal=0;
            var style = null;
            if(casesBorder){
                style = { "--CasesBorder": 'solid '+casesBorder.borderColor+ ' '+casesBorder.borderWidth , "--CasesBorderRadius" : casesBorder.borderRadius } ;
            }
            if(answer.length<=10){
                FinalCases = appearedAnswerArr.map((letter , index)=>{
                    console.log('letter =', letter);
                    if (letter === ' ') {
                        decal--;
                        console.log('space detected');
                        return (<div key={index} indice={index} className="btn space"/>)
                    }else{
                        console.log(strictCaseCorrect);
                        if(strictCaseCorrect.indexOf(index)===-1){
                            console.log('char detected');
                            if(userEssay.length> index+decal){
                                return (<button style={style} key={index} indice={index} className="btn case" onClick={(event)=>{caseClicked(event)}}>{userEssay[index+decal]}</button>)
                            }else{
                                return (<button style={style} key={index} indice={index} onClick={()=>{console.log('clicked !!')}} className="btn case caseEmpty">-</button>)
                            }
                        }else{
                            return (<button style={style} key={index} indice={index} className="btn case caseCorrect">{this.state.answer[index]}</button>)

                        }


                    }
                });
                return(
                    <React.Fragment>
                        <div id="cases" className=" row col-lg-10 col-12  ml-lg-2 mr-lg-2  justify-content-center mt-3">
                            {FinalCases}
                        </div>
                    </React.Fragment>
                )


            }else{
                FinalCases = [];
                let indice = 0;
                let answerSplitted = answer.split(' ');
                console.log('Answer splitted : ', answerSplitted);
                console.log('user Essay is now :', userEssay)
                FinalCases = answerSplitted.map((word, index)=>{
                    indice = indice + word.length;
                    return (<CasesLine casesBorder={casesBorder} word={word} key={index} indiceStart={indice-word.length+index}/>);

                });
                return(
                    <React.Fragment>
                        <div id="cases" className=" row col-lg-10 col-12  ml-lg-2 mr-lg-2  justify-content-center mt-3">
                            {FinalCases}
                        </div>
                    </React.Fragment>
                )
            }



            // return (
            //     <React.Fragment>
            //         <FinalCases/>
            //     </React.Fragment>
            // );
        };

        const CasesLine= ({ casesBorder , word , indiceStart})=>{
            let indice = indiceStart;
            let wordArr = word.split('');
            let spaceIndice = 0;
            console.log('wordArr =', wordArr);
            var style = null;
            if(casesBorder){
                style = { "--CasesBorder": 'solid '+casesBorder.borderColor+ ' '+casesBorder.borderWidth , "--CasesBorderRadius" : casesBorder.borderRadius } ;
            }
            let caseLine = wordArr.map((letter,index)=>{
                spaceIndice = indice + index+1;
                let thisLetter = null;
                console.log('indice =', indice);
                console.log(' index =', index);
                console.log('user essay is ', this.state.userEssay);
                if(indice+index<this.state.userEssay.length ) {
                    thisLetter = this.state.userEssay.charAt(indice + index);
                }
                console.log('this letter :' , thisLetter);
                if(this.state.strictCaseCorrect.indexOf(indice+index)===-1){
                    if(thisLetter!=null){
                        return (<button style={style} key={index} indice={indice+index} onClick={(event)=>{caseClicked(event)}} className="btn case">{thisLetter}</button>)
                    }else{
                        return (<button style={style} key={index} indice={indice+index} onClick={()=>{console.log('clicked !!')}} className="btn case caseEmpty">-</button>)
                    }
                }else{
                    return (<button style={style} key={index} indice={indice+index} onClick={()=>{console.log('clicked !!')}} className="btn case caseCorrect">{this.state.answer.charAt(indice+index)}</button>)
                }
            });
            return(
                <div id="cases" className=" row col-lg-10 col-12  ml-lg-2 mr-lg-2  justify-content-center mt-1">
                    {caseLine}
                    {spaceIndice===this.state.answer.length ? null : <div key={spaceIndice} indice={spaceIndice} className="btn space"/>}
                </div>
            )
        };
        const HeadQuizz = ({img})=>{
            var style = null;
            if(this.props.imageBorder){
                style = { "--ImageQuizBorders": 'solid '+this.props.imageBorder.borderColor+ ' '+this.props.imageBorder.borderWidth , "--ImageQuizRadiusBorders" : this.props.imageBorder.borderRadius } ;
            }
            return (
                <React.Fragment>
                    <div className="row col-12 align-items-center" id="container-2">
                        <div className="offset-3 col-6 mt-2 justify-content-center" id="quizPic" style={{fontSize:'24px'}}>
                            {style!= null ? <img style={style} src={img} alt=""/> : <Loading/>}
                        </div>
                        <div className="offset-1 col-1" id="hintIconContainer" onClick={this.openHelpModal} style={{display : this.state.availableHelps ===0 ? 'none' : 'block' } }>
                            <p style={{color : 'black' , marginBottom : '0.7rem'}}>{this.state.availableHelps}/10</p>
                            <span className="fa-stack ">
                                    <i style={{color : 'var(--lampColor)'}} className="far fa-circle fa-stack-1x fa-3x"></i>
                                    <i style={{color : 'var(--lampColor)'}} className="fas fa-lightbulb fa-stack-2x"></i>
                                </span>
                        </div>
                    </div>
                    <div className="row mt-4" id="#previousNext2">
                        {this.props.indice===0 ? null : <button style={{pointerEvents  : 'all' , backgroundColor:'initial' , border : '0'}} onClick={()=>this.prevNextClicked()}><Link to={'/Categories/'+this.props.category+'/'+this.props.prevType+'/'+Number(Number(this.props.indice)-1)} id="previous2"  className="nav-link">Previous Question</Link></button>}

                        { this.props.indice!==0 && !this.props.last ? <svg className="ml-2 mr-2" height="25" width="1" style={{position: 'relative' , top: '5px'}}>
                            <line x1="0" y1="0" x2="0" y2="100"
                                  style={{stroke: 'var(--blueGreenText)' , strokeWidth:'2' , position: 'absolute'}}/>
                        </svg> : null}
                        {this.props.last ? null : <button style={{pointerEvents  : 'all' , backgroundColor:'initial' , border : '0'}} onClick={()=>this.prevNextClicked()}><Link to={'/Categories/'+this.props.category+'/'+this.props.nextType+'/'+Number(Number(this.props.indice)+1)} id="next2"  className="nav-link">Next Question</Link></button>}
                    </div>
                </React.Fragment>
            )
        };
        const letterClicked=(event)=>{
            if(!this.state.solved){
                console.log(event.target);
                const classes = Array.from(event.target.classList);
                console.log(classes);
                console.log(classes.indexOf('lessOpacity'));
                if(classes.indexOf('lessOpacity') === -1 && this.state.userEssay.length < this.state.answer.length){
                    const letter = event.target.innerText;
                    const oldAnswer = this.userAnswer().split('');
                    console.log('old answer is ', oldAnswer);
                    const position = Number(document.querySelector('.caseEmpty').getAttribute('indice'));
                    oldAnswer[position] = letter;
                    document.querySelectorAll('.caseCorrect').forEach(CaseCorrect=>{
                        if(Number(CaseCorrect.getAttribute('indice'))===position+1 ){
                            oldAnswer[position + 1] = CaseCorrect.innerText;
                        }
                    });
                    console.log('new answer is : ', oldAnswer.filter(letter=>letter!=='-').join(''));
                    this.setState({...this.state , userEssay :oldAnswer.filter(letter=>letter!=='-').join('') });
                    event.target.classList.add('lessOpacity');
                    const firstEmpty = document.querySelector('.caseEmpty');
                    firstEmpty.innerText = letter;
                    firstEmpty.classList.remove('caseEmpty');
                    console.log('FIRST EMPTY IS NO LONGER EMPTY !');

                }
            }
          //  else if(!this.state.solved && this.props.quiz.answer.indexOf(' ')!==-1){
               // console.log(event.target);
               // const classes = Array.from(event.target.classList);
               // console.log(classes);
               // console.log(classes.indexOf('lessOpacity'));
               // if(classes.indexOf('lessOpacity') === -1 && this.state.userEssay.length < this.state.answer.length){
                   // const letter = event.target.innerText;
                    //const oldAnswer = this.userAnswer().split('');
                   // console.log('old answer is ', oldAnswer);
                   // const position = Number(document.querySelector('.caseEmpty').getAttribute('indice'));
                   // if(this.props.quiz.answer[position-1]===' '){
                   //     oldAnswer[position-1]=' ';
                   // }
                   // oldAnswer[position] = letter
                   // document.querySelectorAll('.caseCorrect').forEach(CaseCorrect=>{
                    //    if(Number(CaseCorrect.getAttribute('indice'))===position+1 ){
                   //         oldAnswer[position + 1] = CaseCorrect.innerText;
                   //     }
                  //  });

                  //  console.log('WITHOUT FILTERING THE OLD ANSWER : ', oldAnswer)
                   // console.log('new answer is : ', oldAnswer.filter(letter=>letter!=='-').join(''));
                  //  this.setState({...this.state , userEssay :oldAnswer.filter(letter=>letter!=='-').join('') });
                   // event.target.classList.add('lessOpacity');
                   // const firstEmpty = document.querySelector('.caseEmpty');
                    //firstEmpty.innerText = letter;
                  //  firstEmpty.classList.remove('caseEmpty');
                    //console.log('FIRST EMPTY IS NO LONGER EMPTY !');
               // }
           // }
        };
        const letterClickedKeyboard=(letter)=>{
            console.log('KEYBOARD CLICKED §');
            if(!this.state.solved){
                let letterCase = null;
                document.querySelectorAll('.letter:not(.lessOpacity)').forEach(querySelector => {
                    if( querySelector.innerText.toLowerCase() === letter){
                        if(letterCase===null){
                            letterCase = querySelector;
                        }
                    }
                });
                console.log('Letter case =', letterCase);
                if(letterCase!==null && this.state.userEssay.length< this.state.answer.length){
                    const oldAnswer = this.userAnswer().split('');
                    console.log('old answer is ', oldAnswer);
                    const position = Number(document.querySelector('.caseEmpty').getAttribute('indice'));
                    console.log('POSITION =', position);
                    let minIndice = 50;
                    let firstEmpty = null;
                    document.querySelectorAll('.caseEmpty').forEach(caseEmpty => {
                        let thisIndice = Number(caseEmpty.getAttribute('indice'));
                        if (thisIndice < minIndice) {
                            firstEmpty = caseEmpty;
                            minIndice = thisIndice;
                        }
                    });
                    console.log('FIRST EMPTY ISSSS : ', firstEmpty);
                    firstEmpty.innerText = letter.toUpperCase();
                    firstEmpty.classList.remove('caseEmpty');
                    console.log('FIRST EMPTY IS NO LONGER EMPTY !');
                    oldAnswer[position] = letter.toUpperCase();
                    document.querySelectorAll('.caseCorrect').forEach(CaseCorrect=>{
                        if(Number(CaseCorrect.getAttribute('indice'))===position+1 ){
                            oldAnswer[position + 1] = CaseCorrect.innerText;
                        }
                    });
                    console.log('new answer is : ', oldAnswer.filter(letter=>letter!=='-').join(''));
                    this.setState({...this.state , userEssay :oldAnswer.filter(letter=>letter!=='-').join('') });
                    letterCase.classList.add('lessOpacity');


                }
                // console.log(event.target);
                // const classes = Array.from(event.target.classList);
                // console.log(classes);
                // console.log(classes.indexOf('lessOpacity'));
                // if(classes.indexOf('lessOpacity') === -1 && this.state.userEssay.length < this.state.answer.length){
                //     const letter = event.target.innerText;
                //     const oldAnswer = this.userAnswer().split('');
                //     console.log('old answer is ', oldAnswer);
                //     const position = Number(document.querySelector('.caseEmpty').getAttribute('indice'));
                //     oldAnswer[position] = letter;
                //     document.querySelectorAll('.caseCorrect').forEach(CaseCorrect=>{
                //         if(Number(CaseCorrect.getAttribute('indice'))===position+1 ){
                //             oldAnswer[position + 1] = CaseCorrect.innerText;
                //         }
                //     });
                //     console.log('new answer is : ', oldAnswer.filter(letter=>letter!=='-').join(''));
                //     this.setState({...this.state , userEssay :oldAnswer.filter(letter=>letter!=='-').join('') });
                //     event.target.classList.add('lessOpacity');
                //     const firstEmpty = document.querySelector('.caseEmpty');
                //     firstEmpty.innerText = letter;
                //     firstEmpty.classList.remove('caseEmpty');
                //     console.log('FIRST EMPTY IS NO LONGER EMPTY !');
                //
                // }
            }
        };
        const setAvailableLetters=(lettersArr)=>{
            this.setState({...this.state , letters: lettersArr});
            console.log('Final Letters : ', lettersArr);
        };
        const clearClicked=()=>{
            if(!this.state.solved){
                document.querySelectorAll('.case:not(.caseEmpty):not(.caseCorrect)').forEach(Case=>{
                    const letter = Case.innerText;
                    Case.innerText = '-';
                    Case.classList.add('caseEmpty');
                    let removedOne = false;
                    document.querySelectorAll('.lessOpacity').forEach(Letter=>{
                        if(!removedOne){
                            if(Letter.innerText ===letter){
                                Letter.classList.remove('lessOpacity');
                                removedOne = true;
                            }
                        }
                    })
                });
                this.setState({...this.state , userEssay: ''});

            }

        };
        const caseClicked=(event)=>{
            if(!event.target.classList.contains('caseCorrect')){
                const indice = Number(event.target.getAttribute('indice'));
                const letter = this.state.userEssay.charAt(indice);
                const essay = this.state.userEssay;
                const essayLength = essay.length;
                const newEssay = essay.substring(0, indice).concat(essay.substring(indice + 1, essayLength));
                this.setState({...this.state , userEssay :newEssay});
                let removed = false;
                document.querySelectorAll('.lessOpacity').forEach(letterCase=>{
                    console.log(letterCase);
                    if(letterCase.innerText===letter && !removed){
                        letterCase.classList.remove('lessOpacity');
                        removed = true;
                    }
                })
            }
        };
        // var styleClearBtn = null;
        // if(this.props.casesBorder){
        //     styleClearBtn = { "--CasesBorder": 'solid '+this.props.casesBorder.borderColor+ ' '+this.props.casesBorder.borderWidth , "--CasesBorderRadius" : this.props.casesBorder.borderRadius } ;
        // }
        
        return (
            <React.Fragment>
                <KeyboardEventHandler
                    handleKeys={this.state.lettersLower}
                    onKeyEvent={(key, e) =>{letterClickedKeyboard(key)}} />
                    {/*<nav className="navbar  fixed-top" style={{display : 'flex' , justifyContent: 'space-between'}}>*/}
                    {/*    <div className="navbar-brand"><a href="index.html" target="_blank" style={{color : '#eee'}}><i*/}
                    {/*        className="fas fa-arrow-left fa-2x mr-2"/></a>*/}
                    {/*    </div>*/}
                    {/*    <div className="navbar-nav" id="QuizLevel">*/}
                    {/*        <div>Team Logos</div>*/}
                    {/*        <div>Stage 1 -Level 8</div>*/}
                    {/*    </div>*/}
                    {/*    <ul className="mr-2 navbar-nav  row  align-items-center">*/}
                    {/*        <span style={{color : 'var(--darkBlue)' , width : '30px'}}> i</span>*/}
                    {/*    </ul>*/}
                    {/*</nav>*/}
                    {this.props.indice===0 ? 
                                		<ReCaptcha
                                        ref={ref => this.recaptcha = ref}
                                        sitekey="6LcKwMcZAAAAANfS-XXfr5QWaQrmPEZhmrhijOZt"
                                        action='recaptcha-quiz'
                                        verifyCallback={this.verifyCallback}
                                    /> : null
                                    }
                    <div className='justify-content-center' style={{display : 'flex'}}>
                        <div className='d-none d-md-block ' style={{width : '300px' , height : '700px' }}>
                                        {document.documentElement.clientWidth < 768 ? null : <AdSenseGoogle
                                            client='ca-pub-8839630470444144'
                                            slot='3798553364'
                                            styles={{width : '300px' , height : '700px' }}
                                        /> }
                        </div>
                    <div className="container mt-3">
                            <div className="mt-3 justify-content-center row" id="image">
                                <HeadQuizz img={this.state.image}/>
                                <div className="row col-12 justify-content-center">
                                    <Cases casesBorder={this.props.casesBorder} answer={this.state.answer} userEssay={this.state.userEssay} caseClicked={(e)=>{caseClicked(e)}} strictCaseCorrect={this.state.strictCaseCorrect} />
                                </div>
                                <div id="letters"
                                    className=" row col-lg-10 col-12  ml-lg-2 mr-lg-2  justify-content-center mt-3">
                                    <Letters lettersBorder={this.props.lettersBorder} strictLettersLessOpacity={this.state.strictLettersLessOpacity} answer={this.state.answer} letterClicked={e=>{letterClicked(e)}}
                                            availableLetters={this.state.letters} setAvailableLetters={arr=>{setAvailableLetters(arr)}}/>
                                </div>
                            </div>
                        </div>
                        <div className='d-none d-md-block ' style={{width : '300px' , height : '700px' }}>
                                        {document.documentElement.clientWidth < 768 ? null : <AdSenseGoogle
                                            client='ca-pub-8839630470444144'
                                            slot='3798553364'
                                            styles={{width : '300px' , height : '700px' }}

                                        /> }
                        </div>                    
                    </div>

                    <div className="row justify-content-center mr-0 ml-0 mt-3 mb-3">
                        <button className="btn col-10 col-lg-6" id="clearButton" onClick={()=>{clearClicked()}}> Clear</button>
                    </div>
                    <div className='d-flex justify-content-center'>
                            <div className='d-block d-md-none' style={{width : '320px' , height : '50px' }}>
                            {document.documentElement.clientWidth > 768 ? null :<AdSenseGoogle
                                    client='ca-pub-8839630470444144'
                                    slot='9371643354'
                                    styles={{ width: 320 , height: 50, display: 'inline-block' }}
                                    />
                                    }
                            </div>
                        </div>
                    <Modal isOpen={this.state.isCorrectModalOpen}  toggle={this.toggleCorrectModal}>
                        <ModalHeader toggle={this.toggleCorrectModal}> Correct answer </ModalHeader>
                        <ModalBody>
                            <div  className="row justify-content-center">
                                <div>
                                <p style={{textAlign : 'center' , fontSize : '1.2rem'}}>Well done !</p>
                                {
                                    (document.documentElement.clientWidth >729 && Number(sessionStorage.getItem('AnswerPopUpPub'))!==0 && Number(sessionStorage.getItem('AnswerPopUpPub'))%3===0) || (document.documentElement.clientWidth <729) ?
                                    <div className="justify-content-center" style={{height: '250px' , width:'300px'}}>
                                    <AdSenseGoogle
                                        client='ca-pub-8839630470444144'
                                        slot='7202631239'
                                        styles={{display:'inline-block', width:'300px',height:'250px'}}
                                    />
                                    </div> 
                                    :null 
                                }   
                                    {this.props.last ? <p className='mt-3'  onClick={()=>this.toggleCorrectModal()} style={{ pointerEvents  : 'all' , textAlign : 'center' , fontSize : '1.2rem'}}><Link to={'/'} className="btn-lg m-2 " id="backHomePage"><i className="fa fa-arrow-left"/> Go Next STAGE </Link> </p> : <p className='mt-3'  onClick={()=>this.toggleCorrectModal()}  style={{ pointerEvents  : 'all',textAlign : 'center' , fontSize : '1.2rem'}}><Link to={'/Categories/'+this.props.category+'/'+this.props.nextType+'/'+Number(Number(this.props.indice)+1)} className="btn-lg m-2 " id="backHomePage"><i className="fa fa-arrow-left"/> Go Next Question </Link> </p>}
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.isWrongModalOpen}  toggle={this.toggleWrongModal}>
                        <ModalHeader  toggle={this.toggleWrongModal}> Wrong answer </ModalHeader>
                        <ModalBody  className='justify-content-center d-flex'>
                            <div  className="row justify-content-center">
                                <div >
                                    <p style={{textAlign : 'center' , fontSize : '1.2rem'}}>Try again ! </p>
                                    <div>
                                {
                                    (document.documentElement.clientWidth >729 && Number(sessionStorage.getItem('AnswerPopUpPub'))!==0 && Number(sessionStorage.getItem('AnswerPopUpPub'))%3===0) || (document.documentElement.clientWidth <729) ?
                                    <div className="justify-content-center" style={{height: '250px' , width:'300px'}}>
                                    <AdSenseGoogle
                                        client='ca-pub-8839630470444144'
                                        slot='7202631239'
                                        styles={{display:'inline-block', width:'300px',height:'250px'}}
                                    />
                                    </div> 
                                    :null 
                                }     
 
                                </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.isHelpModalOpen}  toggle={this.toggleHelpModal} id="helpModal">
                        <ModalHeader toggle={this.toggleHelpModal}> Get your help , we hope its clearer now 😉 </ModalHeader>
                        <ModalBody className='justify-content-center d-flex'>
                            <div className="justify-content-center" style={{height: '250px' , width:'300px'}}>
                                        <AdSenseGoogle
                                            client='ca-pub-8839630470444144'
                                            slot='7202631239'
                                            styles={{display:'inline-block', width:'300px',height:'250px'}}
                                        />
                            </div>
                        </ModalBody>
                    </Modal>
                <Modal isOpen={this.state.isPubModalOpen}  toggle={this.togglePubModal} id="helpModal">
                    <ModalHeader toggle={this.togglePubModal}>Advertisment</ModalHeader>
                    <ModalBody style={document.documentElement.clientWidth >729 && Number(sessionStorage.getItem('AnswerPopUpPub'))!==0 && Number(sessionStorage.getItem('AnswerPopUpPub'))%3===0 ? {height: '250px' , width:'300px'} : {}} className='justify-content-center d-flex'>
                    <div className="justify-content-center" style={{height: '250px' , width:'300px' }}>
                                        <AdSenseGoogle
                                            client='ca-pub-8839630470444144'
                                            slot='7202631239'
                                           styles={{display:'inline-block', width:'300px',height:'250px'}}
                                        />
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default QuizLetters;