export const GET_USER='GET_USER';
export const LOADING_USER = 'LOADING_USER';
export const USER_FAILED = 'USER_FAILED';
export const INC_HELP = 'INC_HELP';
export const DEC_HELP = 'DEC_HELP';
//FOR QUIZ CHOICES
export const DEC_HELP2 = 'DEC_HELP2';
export const TRIED_CHOICE = 'TRIED_CHOICE';
export const SET_ORDER_CHOICES = 'SET_ORDER_CHOICES';

// FOR CONTACT US
export const LOADING_SEND_MESSAGE = 'LOADING_SEND_MESSAGE';
export const DONE_SEND_MESSAGE = 'DONE_SEND_MESSAGE';
export const ERR_SEND_MESSAGE = 'ERR_SEND_MESSAGE';
export const CLOSE_MODAL_SEND_MESSAGE = 'CLOSE_MODAL_SEND_MESSAGE';