import './styles.css'
import React , {Component} from 'react'
import img from '../../assets/img/tiger.jpg'
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import avatar from "../../assets/img/avatar_1.png";
import {Link} from "react-router-dom";
import {Loading} from "../loadingComponent";
import AdSense from 'react-adsense';
import {AdSenseGoogle} from '../AdSenseGoogle/AdSenseGoogle'
import { ReCaptcha } from 'react-recaptcha-v3'



class QuizChoices extends Component {
    verifyCallback = (recaptchaToken) => {
		// Here you will get the final recaptchaToken!!!  
		console.log(recaptchaToken, "<= your recaptcha token")
	  }
	
	  updateToken = () => {
		// you will get a new token in verifyCallback
		this.recaptcha.execute();
	  }
    constructor(props) {
        super(props);
        this.state={
            img : this.props.quiz.image,
            availableHelps :this.props.help,
            correctChoice : {choice :this.props.quiz.correct , indice : -1},
            wrongChoices: [{choice : this.props.quiz.wrongs[0] , indice:-1} , {choice : this.props.quiz.wrongs[1] , indice:-1 } , { choice : this.props.quiz.wrongs[2] , indice:-1}],
            isCorrectModalOpen:false,
            isWrongModalOpen : false ,
            isHelpModalOpen : false ,
            tried : this.props.tried,
            correct: false,
            strictWrong: this.props.strictWrong,
            order : this.props.order,
            giftHelp : false,
            isPubModalOpen: false
        }
    }
    toggleCorrectModal=()=>{
        let popUpPub=Number(sessionStorage.getItem('AnswerPopUpPub'));
        if(document.documentElement.clientWidth >729){
            sessionStorage.setItem('AnswerPopUpPub' , (popUpPub+1).toString());
        }
        this.props.triedChoice(this.props.category, this.props.indice, true);
        this.setState({...this.state , isCorrectModalOpen : !this.state.isCorrectModalOpen , tried : true , correct : true});
    };
    openCorrectModal=()=>{

        this.setState({...this.state , isCorrectModalOpen : true , tried : true , correct : true});
    };
    toggleWrongModal=()=>{
        let popUpPub=Number(sessionStorage.getItem('AnswerPopUpPub'));
        if(document.documentElement.clientWidth >729){
            sessionStorage.setItem('AnswerPopUpPub' , (popUpPub+1).toString());
        }
        this.props.triedChoice(this.props.category, this.props.indice, false);
        this.setState({...this.state , isWrongModalOpen : !this.state.isWrongModalOpen , tried : true , correct:false})
    };
    openWrongModal=()=>{

        this.setState({...this.state , isWrongModalOpen : !this.state.isWrongModalOpen , tried : true , correct:false})
    };
    toggleHelpModal=()=>{
        this.props.decHelp2(this.props.category , this.props.indice , this.state.strictWrong[this.state.strictWrong.length-1] , this.state.order);
        this.setState({...this.state , isHelpModalOpen : !this.state.isHelpModalOpen })
    };
    togglePubModal =()=>{
        this.setState({...this.state , isPubModalOpen : !this.state.isPubModalOpen });
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.tried){
           setTimeout(()=>{
               document.querySelectorAll('.wrongChoice').forEach(Wrong=>{

                   Wrong.style.backgroundColor = "var(--choiceWrongBackground)";
                   Wrong.style.color="var(--choiceWrongColor)";
                   Wrong.style.borderColor = "var(--choiceWrongBackground)";
               });
               document.querySelector('.correctChoice').style.backgroundColor = "var(--choiceCorrectBackground)";
               document.querySelector('.correctChoice').style.color="var(--choiceCorrectColor)";
               document.querySelector('.correctChoice').style.borderColor="var(--choiceCorrectBackground)";
           },1) ;
        }
        if(this.state.correct && !this.state.giftHelp && this.state.availableHelps <10){
            this.setState({...this.state , availableHelps : this.state.availableHelps +1 , giftHelp : true });
        }


    }

    componentDidMount() {
        console.log('WIDTH =' , document.documentElement.clientWidth);
        let numbPub=Number(sessionStorage.getItem('pub'));
        let openPub = false;
        if(numbPub%3===0 && numbPub!==0){
            openPub=true;
            sessionStorage.setItem('pub','0');
        }
       if(this.state.order.length===0){
           let indices = [];
           while(indices.length!==3){
               let random3 = Math.floor(Math.random() * 4);
               while(indices.indexOf(random3)!==-1){
                   random3 = Math.floor(Math.random() * 4);
               }
               indices.push(random3);
           }
           indices.push(6 - indices[0] - indices[1] - indices[2]);
           let correctChoice = this.state.correctChoice;
           let wrongChoices = this.state.wrongChoices;
           correctChoice.indice = indices[0];
           wrongChoices[0].indice = indices[1];
           wrongChoices[1].indice = indices[2];
           wrongChoices[2].indice = indices[3];
           let order = [null, null, null, null];
           order[correctChoice.indice] = correctChoice.choice;
           wrongChoices.forEach(Wrong=>{
               order[Wrong.indice] = Wrong.choice;
           });
           this.props.setOrderChoices(this.props.category, this.props.indice, order);
           this.setState({...this.state , correctChoice: correctChoice , wrongChoices: wrongChoices , order : order , isPubModalOpen : openPub});
       }else{
           let correctChoice = this.state.correctChoice;
           let wrongChoices = this.state.wrongChoices;
           console.log('WE HAVE ORDER PROPS !');
           console.log('IT IS : ', this.state.order);
           correctChoice.indice = this.state.order.indexOf(correctChoice.choice);
           wrongChoices[0].indice = this.state.order.indexOf(wrongChoices[0].choice);
           wrongChoices[1].indice = this.state.order.indexOf(wrongChoices[1].choice);
           wrongChoices[2].indice = this.state.order.indexOf(wrongChoices[2].choice);
           this.setState({...this.state , correctChoice: correctChoice , wrongChoices: wrongChoices, isPubModalOpen : openPub});
       }
    }
    choiceClicked=(event)=>{
       if(!this.state.tried && !event.target.classList.contains('choiceHelp')) {
           if(event.target.classList.contains('correctChoice')){
               setTimeout(()=>{
                   this.openCorrectModal();
               },500)
           }else{
               setTimeout(()=>{
                   this.openWrongModal();
               },500)
           }
       }
    };
    openHelpModal=()=>{
        console.log('help Clicked !!');
        if(!this.state.tried){
            let indice = Math.floor(Math.random() * 4);
            let choice = document.querySelectorAll('.choice')[indice].innerText;
            let strictWrong = this.state.strictWrong;
            while(indice === this.state.correctChoice.indice || this.state.strictWrong.indexOf(choice) !==-1 ){
                indice = Math.floor(Math.random() * 4);
                choice=document.querySelectorAll('.choice')[indice].innerText;
            }
            strictWrong.push(choice);
            // document.querySelectorAll('.wrongChoice').forEach(Choice => {
            //     if (Number(Choice.getAttribute('indice')) === indice) {
            //         console.log('add ChoiceHelp class !');
            //         Choice.classList.add('choiceHelp');
            //     }
            // });
            if(strictWrong.length !==3){
                this.setState({...this.state , isHelpModalOpen: true , strictWrong : strictWrong  , availableHelps : this.state.availableHelps-1});
            }else{
                this.setState({...this.state , isHelpModalOpen: true , strictWrong : strictWrong  , availableHelps : this.state.availableHelps-1 , tried : true , correct  : true , isCorrectModalOpen : !this.state.isCorrectModalOpen});
            }
        }
    };
    prevNextClicked=()=>{
        let numbPub = Number(sessionStorage.getItem('pub'));
        sessionStorage.setItem('pub' , (numbPub+1).toString());
    }
    render(){
        const HeadQuizz = ()=>{
            // var style = null;
            // if(this.props.imageBorder){
            //     style = { "--ImageQuizBorders": 'solid '+this.props.imageBorder.borderColor+ ' '+this.props.imageBorder.borderWidth , "--ImageQuizRadiusBorders" : this.props.imageBorder.borderRadius } ;
            // }
            return (
                <React.Fragment>
                    <div className="row col-12 align-items-center" id="container-2">
                        <div className="offset-3 col-6 mt-2 justify-content-center" id="quizPic" style={{fontSize:'24px'}}>
                            <img src={this.state.img} alt=""/>
                        </div>
                        <div className="offset-1 col-1" id="hintIconContainer"  onClick={this.openHelpModal} style={{display : this.state.availableHelps ===0 ? 'none' : 'block' } }>
                            <p style={{color : 'black' , marginBottom : '0.7rem'}}>{this.state.availableHelps}/10</p>
                            <span className="fa-stack ">
                                    <i style={{color : 'var(--lampColor)'}} className="far fa-circle fa-stack-1x fa-3x"></i>
                                    <i style={{color : 'var(--lampColor)'}} className="fas fa-lightbulb fa-stack-2x"></i>
                                </span>
                        </div>
                    </div>
                    <div className="row mt-4 align-items-center" id="#previousNext2">
                        {this.props.indice===0 ? null : <button style={{pointerEvents  : 'all' , backgroundColor:'initial' , border : '0'}} onClick={()=>this.prevNextClicked()}><Link to={'/Categories/'+this.props.category+'/'+this.props.prevType+'/'+Number(Number(this.props.indice)-1)} id="previous2"  className="nav-link">Previous Question</Link></button>}
                        { this.props.indice!==0 && !this.props.last ? <svg className="ml-2 mr-2" height="25" width="1" style={{position: 'relative' , top: '5px'}}>
                            <line x1="0" y1="0" x2="0" y2="100"
                                  style={{stroke: 'var(--blueGreenText)' , strokeWidth:'2' , position: 'absolute'}}/>
                        </svg> : null}
                        {this.props.last ? null : <button style={{pointerEvents  : 'all' , backgroundColor:'initial' , border : '0'}} onClick={()=>this.prevNextClicked()}><Link to={'/Categories/'+this.props.category+'/'+this.props.nextType+'/'+Number(Number(this.props.indice)+1)} id="next2"  className="nav-link">Next Question</Link></button>}
                    </div>
                </React.Fragment>
            )
        };
        const Choices=({correctChoice , wrongChoices , strictWrong})=>{
            let content0 = null;
            let content1 = null;
            let content2 = null;
            let content3=null;
            console.log('CORRECT INDICE : ', correctChoice.indice);
            switch (correctChoice.indice) {
                case 0 :
                    content0 = correctChoice.choice;
                    break;
                case 1 :
                    content1= correctChoice.choice;
                    break;
                case 2 :
                    content2 = correctChoice.choice;
                    break;
                case 3 :
                    content3 = correctChoice.choice;
                    break;
            }
            switch (wrongChoices[0].indice) {
                case 0 :
                    content0 = wrongChoices[0].choice;
                    break;
                case 1 :
                    content1= wrongChoices[0].choice;
                    break;
                case 2 :
                    content2 = wrongChoices[0].choice;
                    break;
                case 3 :
                    content3 = wrongChoices[0].choice;
                    break;
            }
            switch (wrongChoices[1].indice) {
                case 0 :
                    content0 = wrongChoices[1].choice;
                    break;
                case 1 :
                    content1= wrongChoices[1].choice;
                    break;
                case 2 :
                    content2 = wrongChoices[1].choice;
                    break;
                case 3 :
                    content3 = wrongChoices[1].choice;
                    break;
            }
            switch (wrongChoices[2].indice) {
                case 0 :
                    content0 = wrongChoices[2].choice;
                    break;
                case 1 :
                    content1= wrongChoices[2].choice;
                    break;
                case 2 :
                    content2 = wrongChoices[2].choice;
                    break;
                case 3 :
                    content3 = wrongChoices[2].choice;
                    break;
            }
            console.log('CONTENT0 :' , content0 , 'CONTENT1 :', content1,'CONTENT2 :' , content2 , 'CONTENT3 :', content3);
            setTimeout(() => {
                document.querySelectorAll('.choice').forEach(Choice=>{
                    if(Number(Choice.getAttribute('indice')) === Number(correctChoice.indice)){
                        Choice.classList.remove('wrongChoice');
                        Choice.classList.add('correctChoice');
                    }
                })
            }, 1);

            return(
                <div className="col-12 justify-content-center mt-3" id="choices">
                    <div className="row" id="choices1" >
                        <button onClick={(event) =>{this.choiceClicked(event)}}  indice={0} className={strictWrong.indexOf(content0)===-1 ? "wrongChoice choice btn col-5 mb-2 mb-lg-4" : "choiceHelp wrongChoice choice btn col-5 mb-2 mb-lg-4" }  >
                            {content0}
                        </button>
                        <button onClick={(event) =>{this.choiceClicked(event)}} indice={1} className={strictWrong.indexOf(content1)===-1 ? "wrongChoice choice btn col-5 mb-2 mb-lg-4" : "choiceHelp wrongChoice choice btn col-5 mb-2 mb-lg-4" }>
                            {content1}
                        </button>
                    </div>
                    <div className="row" id="choices2">
                        <button onClick={(event) =>{this.choiceClicked(event)}} indice={2} className={strictWrong.indexOf(content2)===-1 ? "wrongChoice choice btn col-5 mb-2 mb-lg-4" : "choiceHelp wrongChoice choice btn col-5 mb-2 mb-lg-4" }>
                            {content2}
                        </button>
                        <button onClick={(event) =>{this.choiceClicked(event)}} indice={3} className={strictWrong.indexOf(content3)===-1 ? "wrongChoice choice btn col-5 mb-2 mb-lg-4" : "choiceHelp wrongChoice choice btn col-5 mb-2 mb-lg-4" }>
                            {content3}
                        </button>
                    </div>
                </div>
            )
        };
        return(
            <React.Fragment>
                {/*<nav className="navbar  fixed-top" style={{display : 'flex' , justifyContent: 'space-between'}}>*/}
                {/*    <div className="navbar-brand"><a href="index.html" target="_blank" style={{color : '#eee'}}><i*/}
                {/*        className="fas fa-arrow-left fa-2x mr-2"/></a>*/}
                {/*    </div>*/}
                {/*    <div className="navbar-nav" id="QuizLevel">*/}
                {/*        <div>Team Logos</div>*/}
                {/*        <div>Stage 1 -Level 8</div>*/}
                {/*    </div>*/}
                {/*    <ul className="mr-2 navbar-nav  row  align-items-center">*/}
                {/*        <span style={{color : 'var(--darkBlue)' , width : '30px'}}> i</span>*/}
                {/*    </ul>*/}
                {/*</nav>*/}
                {this.props.indice===0 ? 
                                		<ReCaptcha
                                        ref={ref => this.recaptcha = ref}
                                        sitekey="6LcKwMcZAAAAANfS-XXfr5QWaQrmPEZhmrhijOZt"
                                        action='recaptcha-quiz'
                                        verifyCallback={this.verifyCallback}
                                    /> : null
                                    }
                <div style={{display : 'flex'}}>
                        <div className='d-none d-md-block ' style={{width : '300px' , height : '700px' }}>
                                        {document.documentElement.clientWidth < 768 ? null : <AdSenseGoogle
                                            client='ca-pub-8839630470444144'
                                            slot='3798553364'
                                            styles={{width : '300px' , height : '700px' }}
                                        /> }
                        </div>
                    <div className="container mt-3">
                        <div className="mt-3 justify-content-center row" id="image">
                            <HeadQuizz/>

                            <Choices correctChoice={this.state.correctChoice} wrongChoices={this.state.wrongChoices} strictWrong={this.state.strictWrong}/>
                        </div>

                    </div>
                    <div className='d-none d-md-block ' style={{width : '300px' , height : '700px' }}>
                                        {document.documentElement.clientWidth < 768 ? null : <AdSenseGoogle
                                            client='ca-pub-8839630470444144'
                                            slot='3798553364'
                                            styles={{width : '300px' , height : '700px' }}
                                        /> }
                        </div>
                </div>            
                <Modal style={{width : '300px'}} isOpen={this.state.isCorrectModalOpen}  toggle={this.toggleCorrectModal}>
                    <ModalHeader toggle={this.toggleCorrectModal}> Correct answer </ModalHeader>
                    <ModalBody>
                        <div style={document.documentElement.clientWidth >729 && Number(sessionStorage.getItem('AnswerPopUpPub'))!==0 && Number(sessionStorage.getItem('AnswerPopUpPub'))%3===0 ? {height: '250px' , width:'300px'} : {}} className="row justify-content-center">
                            <div>
                                <p style={{textAlign : 'center' , fontSize : '1.2rem'}}>Well done !</p>
                                {
                                    (document.documentElement.clientWidth >729 && Number(sessionStorage.getItem('AnswerPopUpPub'))!==0 && Number(sessionStorage.getItem('AnswerPopUpPub'))%3===0) || (document.documentElement.clientWidth <729) ?
                                    <div className="justify-content-center" style={{height: '250px' , width:'300px'}}>
                                    <AdSenseGoogle
                                        client='ca-pub-8839630470444144'
                                        slot='7202631239'
                                        styles={{display:'inline-block', width:'300px',height:'250px'}}
                                    />
                                    </div> 
                                    :null 
                                } 
                                {this.props.last ? <p onClick={()=>this.toggleCorrectModal()} className={'mt-3'} style={{pointerEvents : 'all' ,  textAlign : 'center' , fontSize : '1.2rem'}}><Link to={'/'} className="btn-lg m-2 " id="backHomePage"><i className="fa fa-arrow-left"/> Go Next STAGE </Link> </p> : <p onClick={()=>this.toggleCorrectModal()} style={{pointerEvents : 'all' ,  textAlign : 'center' , fontSize : '1.2rem'}}><Link to={'/Categories/'+this.props.category+'/'+this.props.nextType+'/'+Number(Number(this.props.indice)+1)} className="btn-lg m-2 " id="backHomePage"><i className="fa fa-arrow-left"/> Go Next Question </Link> </p>}
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.isWrongModalOpen}  toggle={this.toggleWrongModal}>
                    <ModalHeader toggle={this.toggleWrongModal}> Wrong answer </ModalHeader>
                    <ModalBody className='justify-content-center d-flex'>
                        <div style={document.documentElement.clientWidth >729 && Number(sessionStorage.getItem('AnswerPopUpPub'))!==0 && Number(sessionStorage.getItem('AnswerPopUpPub'))%3===0 ? {height: '250px' , width:'300px'} : {}} className="row justify-content-center">
                            <div>
                            {
                                    (document.documentElement.clientWidth >729 && Number(sessionStorage.getItem('AnswerPopUpPub'))!==0 && Number(sessionStorage.getItem('AnswerPopUpPub'))%3===0) || (document.documentElement.clientWidth <729) ?
                                    <div className="justify-content-center" style={{height: '250px' , width:'300px'}}>
                                    <AdSenseGoogle
                                        client='ca-pub-8839630470444144'
                                        slot='7202631239'
                                        styles={{display:'inline-block', width:'300px',height:'250px'}}
                                    />
                                    </div> 
                                    :null 
                                } 
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.isHelpModalOpen}  toggle={this.toggleHelpModal} id="helpModal">
                    <ModalHeader toggle={this.toggleHelpModal}> Get your help , we hope its clearer now 😉 </ModalHeader>
                    <ModalBody style={document.documentElement.clientWidth >729 && Number(sessionStorage.getItem('AnswerPopUpPub'))!==0 && Number(sessionStorage.getItem('AnswerPopUpPub'))%3===0 ? {height: '250px' , width:'300px'} : {}} className='justify-content-center d-flex'>
                        <div className="justify-content-center" style={{height: '250px' , width:'300px'}}>
                                        <AdSenseGoogle
                                            client='ca-pub-8839630470444144'
                                            slot='7202631239'
                                            styles={{display:'inline-block', width:'300px',height:'250px'}}
                                        />
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.isPubModalOpen}  toggle={this.togglePubModal} id="pubModal">
                    <ModalHeader toggle={this.togglePubModal}>Advertisment</ModalHeader>
                    <ModalBody style={document.documentElement.clientWidth >729 && Number(sessionStorage.getItem('AnswerPopUpPub'))!==0 && Number(sessionStorage.getItem('AnswerPopUpPub'))%3===0 ? {height: '250px' , width:'300px'} : {}} className='justify-content-center d-flex'>
                    <div className="justify-content-center" style={{height: '250px' , width:'300px'}}>
                                        <AdSenseGoogle
                                            client='ca-pub-8839630470444144'
                                            slot='7202631239'
                                            styles={{display:'inline-block', width:'300px',height:'250px'}}
                                        />
                    </div>
                    </ModalBody>
                </Modal>

            </React.Fragment>
        )
    }
}

export default QuizChoices;