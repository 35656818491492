import * as ACTIONTYPES from './ActionTypes'

export const sendMessageStatus= (state={
    done : false,
    isLoading : false ,
    errMess : null,
    isLoginModalOpen: false,
},action)=>{
    switch (action.type) {
        case ACTIONTYPES.LOADING_SEND_MESSAGE:
            return {...state , isLoginModalOpen : false , done: false , errMess : false ,  isLoading: true};
        case ACTIONTYPES.ERR_SEND_MESSAGE:
            return {...state , isLoginModalOpen : false , done: false , errMess : action.payload ,  isLoading: false};
        case ACTIONTYPES.DONE_SEND_MESSAGE:
            return {...state , isLoginModalOpen : false , done: true , errMess : null ,  isLoading: false};
        case ACTIONTYPES.CLOSE_MODAL_SEND_MESSAGE:
            return {...state , isLoginModalOpen : false , done: false , errMess : null ,  isLoading: false};
        default:
            return state
    }
};