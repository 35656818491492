import React , {Component} from "react"
import {Link} from "react-router-dom";
import { withRouter } from 'react-router-dom';
import AdSense from 'react-adsense';
import {AdSenseGoogle} from './AdSenseGoogle/AdSenseGoogle'
class Footer extends Component{
    constructor(props) {
        super(props);
        this.state={
            homeHeader : ['/'],
        };
    }
    render(){
        const actualPath = this.props.location.pathname;
        if(this.state.homeHeader.indexOf(actualPath)===-1){
            //FOR QUIZZES
            return (
                <React.Fragment>
                    <div id='adFooter mt-2 mb-2' className='d-flex justify-content-center' style={document.documentElement.clientWidth < 970 ? { position : 'fixed' , bottom: '0'  , left : 'calc(50% - 160px)'} : {}}>
                        <div  style={document.documentElement.clientWidth < 970 ?  { width : '320px' , height:'50px' } : {width:'970px' , height : '120px'}}>
                            { document.documentElement.clientWidth < 970 ? 
                                <AdSenseGoogle
                                    client='ca-pub-8839630470444144'
                                    slot='9371643354'
                                    styles={{ width: 320 , height: '50px', display: 'inline-block' }}
                                        />
                                :
                                <AdSenseGoogle
                                client='ca-pub-8839630470444144'
                                slot='5688269390'
                                styles={{ width: '970px' , height: '120px', display: 'inline-block' }}
                                    />    
                            }
                        </div>
                    </div>
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <div id='adFooter mt-2 mb-2' className='d-flex justify-content-center' style={document.documentElement.clientWidth < 970 ? { position : 'fixed' , bottom: '0'  , left : 'calc(50% - 160px)' , zIndex:'2000'} : {}}>
                        <div style={document.documentElement.clientWidth < 970 ?  { width : '320px' , height:'50px' } : {width:'970px' , height : '120px'}}>
                            { document.documentElement.clientWidth < 970 ? 
                                null
                                :
                                <AdSenseGoogle
                                client='ca-pub-8839630470444144'
                                slot='5688269390'
                                styles={{ width: '970px' , height: '120px', display: 'inline-block' }}
                                    />    
                            }
                        </div>
                    </div>
                    <nav style={{height : '52px' , bottom : '0'}} id='footerNavbar' className="navbar navbar-expand-sm navbar-dark bg-dark">
                        <a id='footerBrand' className="navbar-brand ml-5" href="#" style={{fontSize : '1rem'}}>Quiz.vidartus.com Copyright © 2020</a>
                        <ul id='navFooter' className="navbar-nav ml-auto" style={{fontSize : '0.8rem'}}>
                            <li  className="nav-item  footerItem">
                                <Link className="nav-link" to="/privacy-policy">Privacy policy</Link>
                            </li>
                            <li className="nav-item footerItem">
                                <Link to={'/contactUs'} className="nav-link" href="#">Contact us</Link>
                            </li>
                        </ul>
                    </nav>
                </React.Fragment>

                )

        }

    }

}

export default withRouter(Footer);